import styles from "../../style";
import { partners, premiumPartners, premiumPublishers } from "../../constants";
import { unibots, sep } from "../../Assets";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
function Partners() {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        y: ["10vh", "0vh"],
        transition: { ease: "easeOut", duration: 1 },
        duration: 1,
        bounce: 0.3,
      });
    }
  }, [inView]);
  return (
    <section
      ref={ref}
      className={`${styles.flexCenter} flex-col justify-center sm:gap-[4rem] relative `}
    >
      {/* <div className="absolute h-[586px] w-[541px] top-[15%] right-0 z-0">
      <img src={unibots} alt="" />
      </div> */}
      <motion.div
        animate={animation}
        className="text-white w-full flex flex-col sm:pt-8 lg:pb-6 premium sm:mt-[2rem]  mt-0"
        // style={{
        //   background: 'radial-gradient(circle at 100% 100%, #181818 0, #181818 29px, transparent 29px) 0% 0%/30px 30px no-repeat,            radial-gradient(circle at 0 100%, #181818 0, #181818 29px, transparent 29px) 100% 0%/30px 30px no-repeat,            radial-gradient(circle at 100% 0, #181818 0, #181818 29px, transparent 29px) 0% 100%/30px 30px no-repeat,            radial-gradient(circle at 0 0, #181818 0, #181818 29px, transparent 29px) 100% 100%/30px 30px no-repeat,            linear-gradient(#181818, #181818) 50% 50%/calc(100% - 2px) calc(100% - 60px) no-repeat,            linear-gradient(#181818, #181818) 50% 50%/calc(100% - 60px) calc(100% - 2px) no-repeat,            linear-gradient(90deg, #181818 0%, #d5e0e6 100%)',borderRadius: '30px', boxSizing: 'border-box'
        // }}
      >
        <h1 className="md:text-[2rem] text-[24px] font-semibold">
          {premiumPartners.heading}
        </h1>
        <p className="text-[#A1A1AA] border-b-2 xl:border-b-0 text-[14px] pt-4 pb-8">
          {premiumPartners.content}
        </p>
        <div
          className={`flex items-center justify-center flex-wrap w-full lg:gap-8 gap-2`}
        >
          {partners.map((client) =>
            client.type === "premium" ? (
              <div key={client.id} className=" flex flex-wrap mt-4">
                <img
                  src={client.logo}
                  alt="client"
                  className="w-[60px] sm:w-[auto] sm:max-h-[40px]"
                />
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </motion.div>

      <motion.div
        animate={animation}
        className="text-white w-full flex flex-col pt-8 lg:pb-6 publisher sm:mt-0 mt-8"
        // style={{
        //   background: 'radial-gradient(circle at 100% 100%, #181818 0, #181818 29px, transparent 29px) 0% 0%/30px 30px no-repeat,            radial-gradient(circle at 0 100%, #181818 0, #181818 29px, transparent 29px) 100% 0%/30px 30px no-repeat,            radial-gradient(circle at 100% 0, #181818 0, #181818 29px, transparent 29px) 0% 100%/30px 30px no-repeat,            radial-gradient(circle at 0 0, #181818 0, #181818 29px, transparent 29px) 100% 100%/30px 30px no-repeat,            linear-gradient(#181818, #181818) 50% 50%/calc(100% - 2px) calc(100% - 60px) no-repeat,            linear-gradient(#181818, #181818) 50% 50%/calc(100% - 60px) calc(100% - 2px) no-repeat,            linear-gradient(90deg, #181818 0%, #d5e0e6 100%)',borderRadius: '30px', boxSizing: 'border-box'
        // }}
      >
        <h1 className="md:text-[2rem] text-[24px] font-semibold">
          {premiumPublishers.heading}
        </h1>
        <p className="text-[#A1A1AA] text-[14px] border-b-2 xl:border-b-0 pt-4 pb-8">
          {premiumPublishers.content}
        </p>
        <div className="slider">
          <div
            className={`flex items-center justify-center flex-wrap  w-full lg:gap-4 gap-[1rem] slide-track`}
          >
            {partners.map((client) =>
              client.type === "publisher" ? (
                <div
                  key={client.id}
                  className="block flex-wrap mt-4 slide mx-2"
                >
                  <img
                    src={client.logo}
                    alt="client"
                    className="sm:min-w-[40px] sm:w-[auto] sm:max-h-[44px]  w-[auto] max-h-[40px] contain"
                  />
                </div>
              ) : (
                ""
              )
            )}
            {partners.map((client) =>
              client.type === "publisher" ? (
                <div
                  key={client.id}
                  className="sm:block flex-wrap mt-4 slide hidden mx-2"
                >
                  <img
                    src={client.logo}
                    alt="client"
                    className="sm:min-w-[40px] sm:w-[auto] sm:max-h-[44px]  w-[auto] max-h-[30px] contain"
                  />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </motion.div>
    </section>
  );
}

export default Partners;

import { useState, useEffect } from "react";
import Demo from "../Forms/Demo";
import { vector } from "../../Assets";
import { closeBtn } from "../../Assets";
import partnersImg from "../../Assets/nav/Partners.png";
import { partners } from "../../constants";
import { Dialog } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import { feedback } from "../../constants";
import axios from "axios";
export default function Modal(props) {
  let [isOpen, setIsOpen] = useState(false);
  const [work, setWork] = useState(true);
  const [seller, setSeller] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [demoObj, setDemoObj] = useState({
    name: "",
    email: "",
    property: "",
    websiteNumber: "",
    skype: "",
    country: "",
    phone: "",
    pv: "",
    why: "",
  });
  const handleWork = () => {
    setWork(true);
    setSeller(false);
  };
  const handleSeller = () => {
    setWork(false);
    setSeller(true);
  };

  const data = ["1", "2", "3", "4"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselInfiniteScroll = () => {
    if (currentIndex === data.length - 1) {
      return setCurrentIndex(0);
    }
    return setCurrentIndex(currentIndex + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      carouselInfiniteScroll();
    }, 5000);

    return () => clearInterval(interval);
  })

  const discord = () => {
    const whurl = process.env.REACT_APP_KEY;

    const msg = {
      content: `
      Client Name -${demoObj.name},
      Email - ${demoObj.email},
      website - ${demoObj.property ? demoObj.property : "NA"},
      ReSeller Websites - ${
        demoObj.websiteNumber ? demoObj.websiteNumber : "NA"
      },
      SkypeId - ${demoObj.skype ? demoObj.skype : "NA"}, 
      Country - ${demoObj.country ? demoObj.country : "NA"},
      Phone - ${demoObj.phone ? demoObj.phone : "NA"},
      PageVists - ${demoObj.pv} ,
      Why do you want to work with unibots(for ReSellers) - ${
        demoObj.why ? demoObj.why : "NA"
      }
      `,
    };
    // console.log(JSON.stringify(msg));
    fetch(whurl + "?wait=true", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(msg),
    })
      .then((a) => a.json())
      .then(console.log);
  };

  // const handleSend = async () => {
  //   try {
  //     await axios.post(`http://localhost:4000/send_mail`, {
  //       text: demoObj,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     alert("error occured");
  //   }
  // };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log("form submitted succesfully");
      // console.log(demoObj);
      setIsOpen(true);
      // props.setIsModalOpen(false);
      // handleSend();
      discord();
      setTimeout(() => {
        setIsOpen(false);
        props.setIsModalOpen(false);
      }, 2000);
    }

    // return () => {
    //   clearTimeout(interval)
    // }
  }, [formErrors]);

  const handleSubmit = (e) => {
    setFormErrors(validate(demoObj));
    setIsSubmit(true);
  };

  const validate = (values) => {
    const error = {};
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!values.name) {
      error.name = "Name required";
    }
    if (!values.email) {
      error.email = "Email required";
    } else if (!regex.test(values.email)) {
      error.email = "Enter a valid Email";
    }
    if (!values.property && !values.websiteNumber) {
      error.property = "Website/App Url is required";
    }
    // if(!values.country) {
    //   error.country = "Please tell us where are you From?"
    // }
    if (!values.pv && !values.websiteNumber) {
      error.pv = "Please enter Your Daily Page Views";
    }
    return error;
  };

  const handleChange = (e) => {
    setDemoObj((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  return (
    <>
      <AnimatePresence>
        <motion.div
          key="modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="justify-end grid-cols-2  items-center grid overflow-x-hidden overflow-y-auto fixed inset-0 z-[999999] outline-none focus:outline-none  bg-[#181819] "
        >
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="relative z-[9999999999]"
          >
            <div
              className="fixed inset-0 backdrop-blur-sm bg-black/50"
              aria-hidden="true"
            >
              <div className="fixed inset-0 flex items-center justify-center w-full m-auto">
                <Dialog.Panel className="mx-auto w-[500px] rounded bg-white p-[2rem]">
                  <Dialog.Title className="font-bold mb-2 text-[24px]">
                    Thank you for getting in touch!
                  </Dialog.Title>
                  <p>
                    We appreciate you contacting Unibots. One of our Team Member
                    will get back in touch with you soon!Have a great day!
                  </p>
                  {/* ... */}
                </Dialog.Panel>
              </div>
            </div>
          </Dialog>
          <div className="relative w-screen h-[720px] mt-4 mx-auto">
            {/*content*/}
            <div className=" rounded-lg  relative flex flex-col w-[90%] m-auto bg-[#181818] outline-none focus:outline-none">
              {/*header*/}
              <div className="lg:px-6 px-1 mx-6 grid grid-cols-1 visible items-start justify-between border-solid border-slate-200 rounded-t">
                <button
                  className="flex justify-end lg:px-2 pt-2 sm:w-full w-[110%]"
                  type="button"
                  onClick={() => {
                    props.setIsModalOpen(false);
                    // console.log("clicked");
                  }}
                >
                  <img
                    className="lg:mb-6 w-10 h-10 rounded-full"
                    src={closeBtn}
                    alt="cross btn"
                  />
                </button>
              </div>
              {/*body*/}
              <div className="grid grid-cols-1 lg:grid-cols-2">
                <div className="relative pt-0  flex-auto order-2 lg:order-1">
                  <div className="invisible lg:visible grid grid-cols-1 grid-rows-[50px]  items-start justify-between lg:mb-16 border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl px-2 font-semibold text-gray-200  sm:text-left">
                      We empower you to boost your revenue.
                    </h3>
                  </div>
                  {/* <div className="">
                    <img src={vector} alt="Rounded avatar" />
                    <p className="my-4 text-gray-300 text-sm px-2 leading-relaxed">
                      I think Unibots speak to a lot of ad businesses. I looked
                      at a few options, but Unibots' positioning was more
                      flexible. Without it the business wouldn't be scalable
                    </p>
                    <div className="mb-16 my-2 flex items-center space-x-1">
                      <img
                        class=" w-10 h-10 rounded-full"
                        src="https://png.pngtree.com/png-clipart/20190924/original/pngtree-business-people-avatar-icon-user-profile-free-vector-png-image_4815126.jpg"
                        alt="Rounded avatar"
                      />
                      <p className="text-gray-300 text-sm  px-2 leading-relaxed">
                        {" "}
                        - <span className="font-bold">Doc Back</span> owner at
                        Docbao
                      </p>
                    </div>
                  </div> */}
                  <div className="carousel-container w-full mt-[10px]">
                  {feedback.map((card, index) => {
          return (
            <>
              <div
                className={`flex gqp-4 w-[100%] carousel-item border-red-500`}
                style={{ transform: `translate(-${currentIndex * 100}%)` }}
              >
                <div className="w-[100%]">
                  <img
                    src={vector}
                    alt="double_quotes"
                    className="w-[60px] h-[60px] object-contain"
                  />
                  <p className="text-[14px] text-[#A1A1AA] ">{card.content}</p>
                  <div className="flex flex-row justify-between items-end">
                    <div className="flex flex-col">
                      <h4 className="font-poopins font-semibold text-[16px] leading[32px] mt-2 text-white ">
                        {card.name}
                      </h4>
                      <p className="font-poopins font-normal text-[14px] leading[24px] text-[#A1A1AA] ">
                        {card.designation}
                      </p>
                      <p className="font-poopins font-normal text-[12px] leading[24px] text-[#A1A1AA] ">
                        {card.company}
                      </p>
                    </div>
                    <img
                      src={card.img}
                      alt={card.name}
                      className=" rounded-full  items-end"
                    />
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
                  <div className="flex gap-4 flex-col justify-start items-start">
                    <h2 className=" mt-10 mb-4  text-xl text-center underline underline-offset-4 text-gray-200">
                      Trusted by publishers and partners.
                    </h2>
                    <img className="" src={partnersImg} alt="our partners" />
                  </div>
                </div>
                {/*demo form*/}
                <div className="lg:m-14 md:m-8 my-4 text-white bg-[#27272A] flex-auto lg:mt-0 p-6 rounded-2xl order-1 lg:order-2">
                  <div className="text-center flex gap-6 flex-col items-center">
                    <div className="text-white sm:w-[100%] w-full mx-auto flex flex-row justify-around sm:my-[2rem] bg-[#3F3F46] h-auto rounded-full">
                      <div
                        className={`rounded-full w-full font-normal text-center p-2 sm:py-3 text-[13px]   sm:text-[16px]  ${
                          work ? "bg-blue-600" : ""
                        }  ${
                          work ? "text-white  font-bold p-2" : ""
                        } cursor-pointer`}
                        onClick={handleWork}
                      >
                        Work with unibots
                      </div>
                      <div
                        className={`rounded-full w-full text-center font-normal p-2 sm:py-3 text-[14px] sm:text-[16px]  ${
                          seller ? "bg-blue-600" : ""
                        }  ${
                          seller ? "text-white  font-bold p-2" : ""
                        } cursor-pointer`}
                        onClick={handleSeller}
                      >
                        Become a reseller
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 w-full">
                      <div>
                        <input
                          className="border sm:w-full rounded-lg bg-[#27272A] py-3 px-5"
                          name="name"
                          value={demoObj.name}
                          placeholder="Your Name"
                          id="name"
                          onChange={handleChange}
                          required
                        />
                        <span className="text-[14px] block sm:inline text-red-700 font-normal">
                          {formErrors.name}
                        </span>
                      </div>
                      <div>
                        <input
                          className="border sm:w-full rounded-lg bg-[#27272A] py-3 px-5"
                          name="email"
                          id="email"
                          value={demoObj.email}
                          placeholder="Your Email"
                          onChange={handleChange}
                          required
                        />
                        <span className="text-[14px] block sm:inline text-red-700 font-normal">
                          {formErrors.email}
                        </span>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 w-full">
                      <div>
                        {work ? (
                          <>
                            <input
                              className="border sm:w-full rounded-lg py-3 px-5 bg-[#27272A]"
                              name="property"
                              value={demoObj.property}
                              placeholder="Your Website/App URL"
                              id="property"
                              onChange={handleChange}
                              required
                            />
                            <span className="text-[14px] text-red-700 font-normal">
                              {formErrors.property}
                            </span>
                          </>
                        ) : (
                          <input
                            className="border sm:w-full rounded-lg py-3 px-5 bg-[#27272A]"
                            name="websiteNumber"
                            value={demoObj.websiteNumber}
                            placeholder="How many Websites/Apps you are working with"
                            id="property"
                            onChange={handleChange}
                            required
                            type="number"
                          />
                        )}
                      </div>
                      <div>
                        <input
                          className="border sm:w-full rounded-lg py-3 px-5 bg-[#27272A]"
                          name="skype"
                          id="skype"
                          value={demoObj.skype}
                          placeholder="Your Skype ID"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5  w-full">
                      <div>
                        <input
                          className="border sm:w-full rounded-lg py-3 px-5 bg-[#27272A]"
                          name="country"
                          value={demoObj.country}
                          placeholder="Your Country"
                          id="country"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <input
                          className="border sm:w-full rounded-lg py-3 px-5 bg-[#27272A]"
                          name="phone"
                          id="phone"
                          value={demoObj.phone}
                          placeholder="Your Phone No."
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div
                      className={`grid grid-cols-1 ${
                        work ? "sm:grid-cols-2" : ""
                      } gap-5 w-full`}
                    >
                      <div>
                        {work ? (
                          <>
                            <input
                              className="border sm:w-full rounded-lg py-3 px-5 bg-[#27272A]"
                              name="pv"
                              value={demoObj.pv}
                              placeholder="Your Daily Page Views"
                              id="pv"
                              onChange={handleChange}
                              required
                            />
                            <span className="text-[14px] text-red-700 font-normal">
                              {formErrors.pv}
                            </span>
                          </>
                        ) : (
                          <>
                            <textarea
                              rows=""
                              className="border w-[90%] sm:w-full rounded-lg py-3 px-5 bg-[#27272A]"
                              name="why"
                              value={demoObj.why}
                              placeholder="Why do you want to be a unibots Reseller"
                              id="why"
                              onChange={handleChange}
                            />
                          </>
                        )}
                      </div>
                      {/* {toggle ? (
                      <input
                        className="border rounded-lg py-3 px-5 bg-[#27272A]"
                        name="pv"
                        value={demoObj.pv}
                        placeholder="Additional Input Field"
                        id="pv"
                        onChange={handleChange}
                      />
                    ) : null} */}
                    </div>

                    <div className="items-center px-4 py-3 flex w-3/4 gap-5">
                      <button
                        onClick={() => handleSubmit()}
                        className="w-full text-[12px] sm:text-[16px] inline-flex gap-2 items-center justify-center px-[20px] py-[14px] textase font-medium leading-6 text-white whitespace-no-wrap bg-blue-600 border border-blue-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        style={{
                          boxShadow: "0px 6px 20px rgba(0, 122, 255, 0.3)",
                        }}
                      >
                        {work ? "Get Started" : "Let's Go"}
                      </button>
                    </div>
                  </div>
                </div>
                <h3 className="sm:text-3xl text-[16px] lg:hidden px-2 font-semibold text-center text-gray-200 order-1">
                We empower you to boost your revenue.
                </h3>
              </div>
              {/*footer*/}
              <div className="flex lg:hidden items-center justify-center p-6  border-solid border-slate-200 rounded-b">
                <button
                  className=""
                  type="button"
                  onClick={() => props.setIsModalOpen(false)}
                >
                  <img
                    className="lg:mb-2 w-10 h-10 rounded-full"
                    src={closeBtn}
                    alt="cross btn"
                  />
                </button>
              </div>
            </div>
          </div>
        </motion.div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </AnimatePresence>
    </>
  );
}

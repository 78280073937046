import React, {useEffect} from 'react'
import {app_monetize} from "../../Assets"
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {ubapp} from "../../constants"
function Ubapp() {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const imgAn = useAnimation()
  useEffect(() => {
    if (inView) {
      animation.start({
        y: ["20vh","0vh"],
        transition:{ ease: "easeOut", duration: 1},
        duration: 1,
        bounce: 0.3,
      });
      imgAn.start({
        x: ["20vh","0vh"],
        transition:{ ease: "easeOut", duration: 1.5},
        duration: 1,
        bounce: 0.3,
      })
    } 
  }, [inView]);
  return (
    <>
    <div ref={ref} className='sm:flex hidden md:flex-row sm:flex-col items-center h-full'>
    <motion.div     initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }} className='flex flex-col h-full justify-around pl-4'>
    <div className='flex flex-col gap-6'>
    <h1 className='text-[24px]'>{ubapp.heading}</h1>
    <h3 className='text-[18px]'>{ubapp.subHeading}</h3>
    <p className='text-[14px] max-w-[400px] text-[#A1A1AA]'>
    {ubapp.content}
    </p>
    </div>
    <div className="sm:flex justify-start lg:hidden" >
    <img src={app_monetize} alt="video" className="w-[200px]" />
    </div>
    <div>
    {/* <button className='p-2 border-2 rounded-xl w-[210px] h-[50px]'>Quick demo</button> */}
    </div>
    </motion.div>
    {/* image */}
    <motion.div initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }} className="lg:flex flex-1 justify-end sm:hidden" >
    <img src={app_monetize} alt="video" className="min-w-[200px]" />
    </motion.div>
</div>
 {/* mobile */}
 <div className='sm:hidden mt-4 mb-[2rem]'>
    <div className='flex items-stretch h-full p-2'>
        <div className='flex flex-col justify-between mt-2 '>
          <div className='flex flex-col items-stretch gap-4'>
          <h1 className='text-[16px] text-white'>{ubapp.heading}</h1>
          <h3 className='text-[12px]'>{ubapp.subHeading}</h3>
          </div>
        </div>
        {/* image */}
        <div className="w-[50%]">
        <img src={app_monetize}  alt="video" />
        </div>

    </div>
    <p className='text-[12px] px-2 max-w-[500px] text-[#A1A1AA]'>
    {ubapp.content}
      </p>
      {/* <button className="px-4 ml-2 leading-[22px] py-2 text-[14px] mt-2 text-white rounded-lg border-2 border-white">Quick Demo</button> */}
    </div>
</>
  )
}

export default Ubapp
import React, { useState } from "react";
import { global } from "../../Assets";
import { ubText, trustpilot } from "../../Assets";
import styles, { layout } from "../../style";
import { footer } from "../../constants";
import Modal from "../Navbar/ModalDemo";
import { Button, Popover } from 'antd';
function Footer() {
  const [modalOpen, setIsModalOpen] = useState(false);
  const currentURL = window.location.href;
  const handleModal = () => {
    setIsModalOpen(true);
  };
  const [open, setOpen] = useState(false);
  const [openUae, setOpenUae] = useState(false)
  const hide = () => {
    setOpen(false);
  };
  const hideUae = () => {
    setOpenUae(false)
  }
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const handleOpenChangeUae = (newOpen) => {
    setOpenUae(newOpen)
  }
  return (
    <section className="flex lg:mt-[2rem] mb-[2rem]">
      {modalOpen && <Modal setIsModalOpen={setIsModalOpen} />}
      <div className="flex-1 flex flex-col justify-between lg:items-start items-center text-white lg:min-h-[500px] h-auto">
        <div className="sm:mt-[30%]  w-full text-center lg:text-left">
          <h2 className="font-poppins font-semibold xs:text-[48px] text-[24px] text-white leading-[66.8px] w-full">
            {footer.heading}
          </h2>
          <p
            className={`w-full font-poppins font-normal text-[#A1A1AA] text-[14px] leading-[20.8px] lg:max-w-[350px]`}
          >
            {footer.content}
          </p>
          <button
            type="button"
            onClick={() => handleModal()}
            className="hidden w-[210px] my-4 text-[12px] sm:text-[16px] sm:inline-flex gap-2 items-center justify-center px-[20px] py-[14px] textase font-medium leading-6 text-white whitespace-no-wrap bg-blue-600 border border-blue-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            style={{ boxShadow: "0px 6px 20px rgba(0, 122, 255, 0.3)" }}
            // className={`m-auto py-2  text-white px-6 bg-[#007AFF] font-poppins font-medium text-[16px]  outline-none mt-10`}
          >
            Get Started
          </button>
          {/* <div className="mt-4 flex sm:justify-start justify-center">
            <img
              src={trustpilot}
              alt="Trust Pilot"
              className=" opacity-[70%]"
            ></img>
          </div> */}
        </div>
        <div className="my-4 lg:hidden relative text-[10px]">
          <img src={global} alt="global" />

          <div className="flex flex-col absolute top-[30%] justify-center items-center sm:gap-[2rem] gap-[10px] w-full">
          {/* <Popover
      content={<div onClick={hide} className="flex flex-col">
      <div className="font-medium">
      Samosa Ads Inc 
      </div>
      <div>
    8 The Green, Suite B, 
    Dover, DE 19901, USA
      </div>
    </div>}
      title="Reach Us"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayStyle={{ width: 150 }}
    >
      </Popover> */}
            <div className=" text-white px-4 sm:px-4 sm:text-[16px] cursor-pointer rounded-full bg-[#40C4FF] border-[1px] border-[#1F9AD1] bg-opacity-[45%]">
              Delaware, USA
            </div>
            <div className=" text-white px-4 sm:px-4 sm:text-[16px] cursor-pointer rounded-full bg-[#536DFE] border-[1px] border-[#2540D3] bg-opacity-[45%]">
              United Kingdom
            </div>
      {/* <Popover
      content={<div onClick={hideUae} className="flex flex-col">
        <p className="font-medium">

        SOMO Media LLC 
        </p>
        <p>

      Sharjah Media City, 
      SHAMS, Sharjah, UAE 515000
        </p>
      </div>}
      title="Reach Us"
      trigger="click"
      open={openUae}
      onOpenChange={handleOpenChangeUae}
      overlayStyle={{ width: 150 }}
    >
    </Popover> */}
     <div className=" text-white px-4 sm:px-4 sm:text-[16px] cursor-pointer rounded-full bg-[#3663EB] border-[1px] border-[#FF00E5] ">
              Sharjah,UAE 
            </div>
           
            
            <div className=" text-white px-2 sm:px-4 sm:text-[16px] cursor-pointer  rounded-full border-[1px] border-[#DBFF00] bg-[#ddff007d]">
              Delhi,India
            </div>
            <div className=" text-white px-2 sm:px-4 sm:text-[16px] cursor-pointer rounded-full border-[1px] border-[#FF00E5] bg-[#ff00e655]">
              Ho Chi Minh,Vietnam
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:items-start items-center">
          <img src={ubText} alt="unibots" className="object-fit" />
          <p className="text-[#71717A] mt-2 text-[12px] text-center lg:text-left">
            {footer.mission}
          </p>
        </div>
        <div className="w-full flex justify-center mt-2 lg:hidden">
          <ul className="flex flex-1 flex-wrap w-full justify-evenly gap-2 text-[12px] font-light">
            {/\.(com)\//.test(currentURL) ? (
              <>
                <a
                  className="cursor-pointer"
                  href="https://unibots.com/terms_and_conditions_unibots.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>Terms & Conditions</li>
                </a>
                <a
                  className="cursor-pointer"
                  href="https://unibots.com/privacy_policy_unibots.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>Privacy & Policies</li>
                </a>{" "}
              </>
            ) : (
              <>
                <a
                  className="cursor-pointer"
                  href="https://unibots.in/terms_and_conditions_unibots.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>Terms & Conditions</li>
                </a>
                <a
                  className="cursor-pointer"
                  href="https://unibots.in/privacy_policy_unibots.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>Privacy & Policies</li>
                </a>
              </>
            )}
            <a
              href="mailto:publisher@unibots.com"
              className="text-center w-full"
            >
              <li className="flex justify-center items-center text-[12px]">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 12 12"
                  >
                    <path
                      fill="white"
                      d="M3.5 3A1.5 1.5 0 0 0 2 4.5v3A1.5 1.5 0 0 0 3.5 9h5A1.5 1.5 0 0 0 10 7.5v-3A1.5 1.5 0 0 0 8.5 3h-5ZM3 4.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v.17L6 5.956L3 4.67V4.5Zm0 1.258l2.803 1.201a.5.5 0 0 0 .394 0L9 5.758V7.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5V5.758Z"
                    />
                  </svg>
                </span>
                publisher@unibots.com
              </li>
            </a>
          </ul>
        </div>
      </div>
      {/* mobile city */}

      <div className=" flex-col  text-white relative text-[12px] lg:flex hidden">
      {/* <Popover
      content={<div onClick={hide} className="flex flex-col">
        <div className="font-medium">
        Samosa Ads Inc
        </div>
        <div className="font-md">
      8 The Green, Suite B, 
      Dover, DE 19901, USA
        </div>
      </div>}
      title="Reach Us"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayStyle={{ width: 300 }}
    >
    </Popover> */}
      <div className="absolute text-white top-[33%] right-[63%] cursor-pointer px-4 rounded-full bg-[#40C4FF] border-[1px] border-[#1F9AD1] bg-opacity-[45%]">
          Delaware, USA
        </div>
        
        <div className="absolute text-white top-[24%] right-[42%] cursor-pointer px-4 rounded-full border-[1px] border-[#536DFE] bg-[#2540D3] bg-opacity-[45%]">
          United Kingdom
        </div>
        
        <div className="absolute text-white top-[37%] left-[70%] cursor-pointer px-4 rounded-full border-[1px] border-[#DBFF00] bg-[#ddff007d]">
          Delhi, India
        </div>
        {/* <Popover
      content={<div onClick={hideUae} className="flex flex-col">
      <p className="font-medium">

      SOMO Media LLC 
      </p>
      <div className="text-sm">
    Sharjah Media City, 
    SHAMS, Sharjah, UAE 515000
      </div>
    </div>}
      title="Reach Us"
      trigger="click"
      open={openUae}
      onOpenChange={handleOpenChangeUae}
      overlayStyle={{ width: 300 }}
    >
    </Popover> */}
     <div className="absolute text-white top-[45%] left-[53%] cursor-pointer px-4 rounded-full bg-[#5DB9FD] border-[1px] border-[#7e93e6] bg-opacity-[45%] ">
          Sharjah,UAE
        </div>
        
        <div className="absolute text-white top-[55%] left-[80%] px-4 cursor-pointer rounded-full border-[1px] border-[#FF00E5] bg-[#ff00e655]">
          Ho Chi Minh,Vietnam
        </div>
        <img src={global} alt="card" />
        <div className="mt-2 pr-12">
          <ul className="flex items-center justify-end gap-12">
            {/\.(com)\//.test(currentURL) ? (
              <>
                <a
                  className="cursor-pointer"
                  href="https://unibots.com/terms_and_conditions_unibots.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>Terms & Conditions</li>
                </a>
                <a
                  className="cursor-pointer"
                  href="https://unibots.com/privacy_policy_unibots.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>Privacy & Policies</li>
                </a>{" "}
              </>
            ) : (
              <>
                <a
                  className="cursor-pointer"
                  href="https://unibots.in/terms_and_conditions_unibots.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>Terms & Conditions</li>
                </a>
                <a
                  className="cursor-pointer"
                  href="https://unibots.in/privacy_policy_unibots.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>Privacy & Policies</li>
                </a>
              </>
            )}
            <a href="mailto:publisher@unibots.com">
              <li className="flex justify-center items-center text-[12px]">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 12 12"
                  >
                    <path
                      fill="white"
                      d="M3.5 3A1.5 1.5 0 0 0 2 4.5v3A1.5 1.5 0 0 0 3.5 9h5A1.5 1.5 0 0 0 10 7.5v-3A1.5 1.5 0 0 0 8.5 3h-5ZM3 4.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v.17L6 5.956L3 4.67V4.5Zm0 1.258l2.803 1.201a.5.5 0 0 0 .394 0L9 5.758V7.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5V5.758Z"
                    />
                  </svg>
                </span>
                publisher@unibots.com
              </li>
            </a>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Footer;

import React, { useEffect, useState } from "react";
import { unibots } from "../../Assets";
function Icon() {
  const [scroll, setScroll] = useState(100);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // setScroll(window.scrollY > window.screen.height / 2);
      let scrollRatio = window.scrollY / window.document.body.offsetHeight;
      // console.log(scrollRatio)
      if (scrollRatio <= 0.13) setScroll(100);
      else if (scrollRatio > 0.2 && scrollRatio < 0.4) setScroll(50);
      else if (scrollRatio > 0.4 && scrollRatio < 0.6) setScroll(25);
      else setScroll(10);
    });
  }, []);

  return (
    <div
      className={
        scroll == 100
          ? "fixed top-[50%] left-[50%] mt-[-50%] ml-[-25%] sm:mt-0 sm:ml-0 sm:top-[15%] sm:right-[10%] w-1/3 opacity-0"
          : scroll == 50
          ? "fixed  top-[50%] left-[50%] mt-[-50%] ml-[-25%] sm:mt-0 sm:ml-0  sm:top-[15%] sm:right-[10%] w-1/3 opacity-20"
          : scroll == 25
          ? "fixed  top-[50%] left-[50%] mt-[-50%] ml-[-25%] sm:mt-0 sm:ml-0  sm:top-[15%] sm:right-[10%] w-1/3 opacity-25"
          : "fixed  top-[50%] left-[50%] mt-[-50%] ml-[-25%] sm:mt-0 sm:ml-0  sm:top-[15%] sm:right-[10%] w-1/3 opacity-10"
      }
      style={{ zIndex: "0" }}
    >
      <img src={unibots} className="min-w-[250px]" alt="UB Icon"></img>
    </div>
  );
}

export default Icon;

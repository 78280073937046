import { useState, useEffect } from "react";
import styles from "../../style";
import { heroImg, rocket, play, certi, trustpilot } from "../../Assets";
import { Dialog } from "@headlessui/react";
import Modal from "../Navbar/ModalDemo";
import Player from "./Player";
import { hero } from "../../constants";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Home() {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const textAni = useAnimation();
  const btnAni = useAnimation();
  const imgStart = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: ["20vh", "0vh"],
        transition: { duration: 1 },
      });
      textAni.start({
        x: ["-20vh", "0vh"],
        transition: { duration: 1 },
      });
      btnAni.start({
        y: ["20vh", "0vh"],
        transition: { duration: 1 },
      });
      imgStart.start("visible");
    }
  }, [inView]);

  let [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <section
        ref={ref}
        id="home"
        className={`flex md:flex-row flex-col sm:${styles.paddingY} sm:mt-[6rem] relative`}
      >
        {/* <div
        className="absolute z-[0] w-[80%] h-[30%] rounded-full top-[-20%] left-0 right-0 ml-auto mr-auto text-white bg-[#3B82F6] blur-[200px]"
      >
      </div> */}
        {modalOpen && <Modal setIsModalOpen={setIsModalOpen} />}
        <div
          className={`flex justify-center items-center sm:hidden mt-[2rem] relative`}
        >
          <motion.img
            initial={{ opacity: 0, scale: 0.99 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
            src={heroImg}
            alt="robot"
            className="w-[45%] h-[full] relative z-[5]"
          />
        </div>

        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="relative z-50 bg-red-500"
        >
          <div
            className="fixed inset-0 backdrop-blur-sm bg-black/50"
            aria-hidden="true"
          />
          <div className="fixed inset-0 flex items-center justify-center w-full m-auto">
            {/* The actual dialog panel  */}
            <Dialog.Panel className="mx-auto w-full rounded">
              {/* <button
              type="button"
              onClick={() => setIsOpen(false)}
              class="absolute top-5 right-20 inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out w-[120px]"
            >
              Close
            </button> */}
              <Player setClose={closeModal} />
              {/* ... */}
            </Dialog.Panel>
          </div>
        </Dialog>

        <div
          className={` flex-1 sm:${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6 w-[100%] sm:mt-0 mt-8`}
        >
          <motion.div className="flex flex-row flex-wrap justify-between items-center w-full sm:mt-0">
            <h1
              id="linehead"
              className=" flex-1 font-poppins font-[800] ss:text-[72px] sm:text-[60px] text-[36px] text-white ss:leading-[100px] sm:leading-[70px] leading-[40px] text-center sm:text-left"
            >
              <div className="sm:text-[60px] text-[36px] sm:leading-[70px] leading-[40px]">
                <span>NEXT</span> <span>GEN</span> <span>ADVERTISING</span>
                <br className="sm:block hidden" />{" "}
                <span className="text-gradient">EVOLVED</span>
              </div>
            </h1>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.99 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 2.5 }}
            className=" flex relative  items-center sm:mt-4 mt-0"
          >
            <div
              className={`hidden sm:absolute sm:block  -ml-0.5 h-full w-[3px] bg-white`}
            ></div>
            <p
              className={`sm:${styles.paragraph} text-[14px] max-w-[570px] h-full sm:mt-5 mt-4 text-[#BFDBFE] sm:ml-6 text-center sm:text-left`}
            >
              {hero.subheading}
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.99 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 2.5 }}
            className="flex sm:flex-row flex-col items-center gap-4 mt-8"
          >
            <button
              onClick={() => setIsModalOpen(true)}
              className="w-[210px] text-[12px] sm:text-[16px] inline-flex gap-2 items-center justify-center px-[20px] py-[14px] textase font-medium leading-6 text-white whitespace-no-wrap bg-blue-600 border border-blue-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              style={{ boxShadow: "0px 6px 20px rgba(0, 122, 255, 0.3)" }}
            >
              <img src={rocket} alt="rocket"></img>Get a Demo
            </button>
            <button
              onClick={() => setIsOpen(true)}
              className="w-[210px] inline-flex gap-2 items-center justify-center px-[20px] py-[14px] text-base font-medium leading-6 text-[#007AFF] whitespace-no-wrap bg-[rgba(0, 122, 255, 0.15)] border border-blue-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <img src={play} alt="play"></img>Watch a Trailer
            </button>
          </motion.div>
          <div className="sm:mt-[5rem] mt-[2rem] flex sm:flex-row flex-col gap-6 justify-center sm:items-center items-center">
            <img
              src={certi}
              alt="google certified"
              className="sm:w-[250px] w-[200px]"
            ></img>
            <img
              src={trustpilot}
              alt="Trust Pilot"
              className=" sm:w-fit w-[150px] opacity-[75%]"
            ></img>
          </div>
        </div>

        <motion.div
          animate={imgStart}
          initial={{ opacity: 0, scale: 0.95 }}
          variants={{
            visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
            hidden: { opacity: 0, scale: 0 },
          }}
          className={`md:flex justify-center items-center hidden md:mr-[24px] pr-6 lg:pr-0 relative`}
        >
          <motion.img
            src={heroImg}
            alt="robot"
            className="w-[full] h-[full] relative z-[5]"
          />
          {/* <div className="absolute z-[0] w-[40%] h-[85%] rounded-full bottom-40 white__gradient"></div>
        <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient"></div> */}
        </motion.div>
        <div className="arrow bounce flex justify-center p-8 sm:mb-4 h-auto z-[500] sm:hidden">
          <svg class="arrows" onClick={() => window.scrollBy(0, 800)}>
            <path class="a1" d="M0 0 L30 32 L60 0"></path>
            <path class="a2" d="M0 20 L30 52 L60 20"></path>
            <path class="a3" d="M0 40 L30 72 L60 40"></path>
          </svg>
        </div>
      </section>
      <div className="arrow bounce sm:flex justify-center lg:mt-[4rem] p-8 mb-4 h-auto z-[500] hidden">
        <svg class="arrows" onClick={() => window.scrollBy(0, 800)}>
          <path class="a1" d="M0 0 L30 32 L60 0"></path>
          <path class="a2" d="M0 20 L30 52 L60 20"></path>
          <path class="a3" d="M0 40 L30 72 L60 40"></path>
        </svg>
      </div>
    </>
  );
}

export default Home;

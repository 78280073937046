import React, { useEffect } from "react";
import { banner } from "../../Assets";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ubbanner } from "../../constants";
function UbBanner() {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const imgAn = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        y: ["20vh", "0vh"],
        transition: { ease: "easeOut", duration: 1 },
        duration: 1,
        bounce: 0.3,
      });
      imgAn.start({
        x: ["20vh", "0vh"],
        transition: { ease: "easeOut", duration: 1.5 },
        duration: 1,
        bounce: 0.3,
      });
    }
  }, [inView]);
  return (
    <>
      <div
        ref={ref}
        className="sm:flex hidden items-center h-full md:flex-row sm:flex-col"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col h-full justify-around pl-4"
        >
          <div className="flex flex-col lg:gap-6">
            <h1 className="text-[24px] ">{ubbanner.heading}</h1>
            <h3 className="text-[18px]">{ubbanner.subHeading}</h3>
            <p className="text-[14px] max-w-[400px] text-[#A1A1AA]">
              {ubbanner.content}
            </p>
          </div>
          <div className="sm:flex justify-start lg:hidden">
            <img src={banner} alt="video" className="w-[300px]" />
          </div>
          <div className="flex gap-4 items-center max-w-[500px] flex-wrap">
            <div>
              <a
                href="https://demos.unibots.com/inimageads.html"
                target="_blank"
                rel="noreferrer"
              >
                <button className="px-4  border-2 rounded-xl w-[200px] h-[40px]">
                  In-Image Ads
                </button>
              </a>
            </div>
            <div>
              <a
                href="https://demos.unibots.com/interscrollerads.html"
                target="_blank"
                rel="noreferrer"
              >
                <button className="px-4 border-2 rounded-xl w-[200px] h-[40px]">
                  Interscroller Ads
                </button>
              </a>
            </div>
            <div>
              <a
                href="https://demos.unibots.com/anchorads.html"
                target="_blank"
                rel="noreferrer"
              >
                <button className="px-4 border-2 rounded-xl w-[200px] h-[40px]">
                  Anchor Ads
                </button>
              </a>
            </div>
            <div>
              <a
                href="https://demos.unibots.com/popupads.html"
                target="_blank"
                rel="noreferrer"
              >
                <button className="px-4 border-2 rounded-xl w-[200px] h-[40px]">
                  Pop-up Ads
                </button>
              </a>
            </div>
            <div className="flex-auto max-w-[416px]">
              <a
                href="https://demos.unibots.in/chained_docked.html"
                target="_blank"
                rel="noreferrer"
              >
                <button className="px-4 border-2 w-full rounded-xl h-[40px]">
                  Triplex banner
                </button>
              </a>
            </div>
          </div>
        </motion.div>
        {/* image */}

        <motion.div
          initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="lg:flex flex-1 justify-end sm:hidden"
        >
          <img src={banner} alt="video" className="min-w-[200px]" />
        </motion.div>
      </div>
      {/* mobile */}
      <div className="sm:hidden mt-4 mb-[2rem]">
        <div className="flex items-stretch h-full p-2">
          <div className="flex flex-col justify-between mt-2">
            <div className="flex flex-col items-stretch gap-4">
              <h1 className="text-[16px] text-white">{ubbanner.heading}</h1>
              <h3 className="text-[12px]">{ubbanner.subHeading}</h3>
            </div>
          </div>
          {/* image */}
          <div className="w-[90%] mt-4">
            <img src={banner} alt="video" />
          </div>
        </div>
        <p className="text-[12px] px-2 max-w-[500px] text-[#A1A1AA]">
          {ubbanner.content}
        </p>
        <div className="mt-[1rem] flex flex-1 flex-wrap w-full gap-2">
          <a
            href="https://demos.unibots.com/inimageads.html"
            className="w-[48%]"
            target="_blank"
            rel="noreferrer"
          >
            <button className="px-4  leading-[22px] py-2 w-full text-[14px] mt-2 text-white rounded-lg border-2 border-white">
              In-Image Ads
            </button>
          </a>
          <a
            href="https://demos.unibots.com/interscrollerads.html"
            className="w-[48%]"
            target="_blank"
            rel="noreferrer"
          >
            <button className="px-4  leading-[22px] py-2 w-full text-[14px] mt-2 text-white rounded-lg border-2 border-white">
              Interscroller Ads
            </button>
          </a>
          <a
            href="https://demos.unibots.com/anchorads.html"
            className="w-[48%]"
            target="_blank"
            rel="noreferrer"
          >
            <button className="px-4  leading-[22px] py-2 w-full text-[14px] mt-2 text-white rounded-lg border-2 border-white">
              Anchor Ads
            </button>
          </a>
          <a
            href="https://demos.unibots.com/popupads.html"
            className="w-[48%]"
            target="_blank"
            rel="noreferrer"
          >
            <button className="px-4 leading-[22px] w-full py-2 text-[14px] mt-2 text-white rounded-lg border-2 border-white">
              Pop-up Ads
            </button>
          </a>

          <a
            href="https://demos.unibots.in/chained_docked.html"
            target="_blank"
            rel="noreferrer"
            className="w-[98%]"
          >
            <button className="px-4 leading-[22px] w-full py-2 text-[14px] mt-2 text-white rounded-lg border-2 border-white">
              Triplex banner
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default UbBanner;

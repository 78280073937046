import React, { useEffect, useState, useContext } from "react";
import Home from "./Screens/Home/Home";
import Navbar from "./Screens/Navbar/Navbar";
import Partners from "./Screens/Partners/Partners";
import Products from "./Screens/Products/Products";
import Numbers from "./Screens/Numbers/Numbers";
import Why from "./Screens/Why/Why";
import styles from "./style";
import { motion } from "framer-motion";
import Testimonials from "./Screens/Testimonials/Testimonials";
import Footer from "./Screens/Footer/Footer";
import Icon from "./Screens/Home/Icon";
import { ubIcon, ubText, mobilebg, sep } from "./Assets";
import Modal from "./Screens/Navbar/ModalDemo";
import { LanguageContext } from "./Context/LangContext";
import { navLinks } from "./constants";
import { Routes, Route, Navigate } from "react-router-dom";
import Quiztwiz from "./Screens/quiztwiz/Quiztwiz";
import NotFound from "./Screens/NotFound";
import Arbitrage from "./Screens/arbitrage/Arbitrage";
import GameContact from "./Screens/gamecontact/GameContact";
const isMobile = () => {
  let check = false;
  ((a) => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
let checkMobile = isMobile();
// console.log(checkMobile);

function App() {


  // if (window.location.pathname === "/sellers.json") {
  //   window.location.href = "https://cdn.unibots.in/sellers/sellers.json";
  // }

  return (
   
      <Routes>
      <Route path="/" element={<QuizHome />} />
      <Route path="/quiztwiz" element={<Quiztwiz />} />
      <Route path="/quizgame" element={<Arbitrage />} />
      <Route path="/contactus" element={<GameContact />} />
      <Route path="*" element={<NotFound />} />
      </Routes>
   
  );
}



function QuizHome(){
  const [fixNav, setFixNav] = useState(false);
  const [lang, setLang] = useState("english");

  const getVisitorCountry = async () => {
    const clientCountry = await window.fetch("https://ip2c.org/self");
    const clientData = await clientCountry.text();
    const res = await getCountry(clientData);
    // console.log(res);
    // if(res === "India"){
    //     setLang("indonesian")
    // }

    // setLang(res)
    function getCountry(data) {
      const [status, CO, COD, country] = String(data).split(";");
      if (status !== "1") {
        throw new Error("Unable to fetch country");
      }
      return country;
    }
  };

  getVisitorCountry();

  const store = {
    lang: lang,
    changeLang: (name) => {
      setLang(name);
    },
  };
  const showNav = () => {
    if (
      window.scrollY >= 600 &&
      (checkMobile ? window.scrollY <= 6000 : window.scrollY <= 4000)
    ) {
      setFixNav(true);
    } else {
      setFixNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", showNav);
  }, [window.screenY]);
  const [modalOpen, setIsModalOpen] = useState(false);
  const sitelang = useContext(LanguageContext);
  return(
    <LanguageContext.Provider value={store}>
    <div className="bg-primary w-full overflow-hidden">
    <motion.div
      initial={{ opacity: 0, scale: 0.99 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 2 }}
      className="lg:h-screen h-auto heroBackground"
    >
      {/* NavBar section */}
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
          {fixNav ? (
            <>
              {modalOpen && <Modal setIsModalOpen={setIsModalOpen} />}
              {!modalOpen && (
                <nav
                  className={`xl:max-w-[full] sm:h-[6rem] bg-opacity-50 h-[4rem] bg-gradient-to-b from-[#181818] via-[#181818] to-transparent  md:py-2  w-full  fixed top-0 left-[50%] translate-x-[-50%]  z-50 flex  justify-between items-center navbar`}
                >
                  <a
                    href="#Nav"
                    className="flex gap-2 sm:w-fit justify-start w-[100px] ml-2 sm:ml-[10%]"
                  >
                    <div className="flex gap-2 sm:w-fit justify-start w-[100px] ml-2 sm:ml-[10%]">
                      <img src={ubIcon} className="w-[20px]" alt="bank" />
                      <img src={ubText} className="w-full" alt="unibots" />
                    </div>
                  </a>

                  <div className="sm:mr-[10%] mr-2 flex">
                    <ul
                      className="list-none md:flex hidden
                      justify-end items-center w-full"
                    >
                      {navLinks.map((nav, index) => (
                        <li
                          key={nav.id}
                          // onClick={() => handleModal(nav.id)}
                          className={`shrink-0 cool-link w-full md:w-fit font-poppins font-normal cursor-pointer text-[16px] text-white ${
                            index === navLinks.length - 2 ? "mr-0" : "mr-10"
                          }`}
                        >
                          {index === navLinks.length - 1 ? (
                            ""
                          ) : (
                            <a href={`#${nav.title}`}>{nav.title}</a>
                          )}
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => setIsModalOpen(true)}
                      className="bg-blue-600  border font-semibold text-white border-blue-700 shadow-sm md:w-[210px] w-[150px] md:px-[20px] md:py-[10px] px-[10px] py-[5px] text-[14px] rounded-md"
                      style={{
                        boxShadow: "0px 6px 20px rgba(0, 122, 255, 0.3)",
                      }}
                    >
                      Get Started
                    </button>
                  </div>
                </nav>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* Hero Section */}
      <div className={`${styles.flexStart} min-h-[650px] sm:mt-4 relative`}>
        <div className={`${styles.boxWidth}  `}>
          <Home />

          {/* <div className="scroll-downs z-50">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </div> */} 
        </div>
      </div>
    </motion.div>

    {/* sections */}
    <div
      className={`bg-primary ${styles.paddingX} ${styles.flexStart} sm:mt-0 mt-[2rem]`}
    >
      <div className={`${styles.boxWidth}`}>
        {/* <Who /> */}
        <Icon />

        <Products />

        {/* <div className="flex justify-center sm:my-[6rem] my-[4rem] opacity-50">
          <img src={sep} alt="seperator" />
        </div> */}
        <div class="m-20 w-full h-[1px] mx-auto bg-gradient-to-r from-transparent via-[#A1A1AA] to-transparent"></div>
        <Why />

        <div
          id="Partners"
          class="m-20 w-full h-[1px] mx-auto bg-gradient-to-r from-transparent via-[#A1A1AA] to-transparent"
        ></div>

        <Partners />
        <div class="m-20 w-full h-[1px] mx-auto bg-gradient-to-r from-transparent via-[#A1A1AA] to-transparent"></div>

        {/* <Ready /> */}

        <Testimonials />
        <div
          id="About Us"
          class="m-20 w-full h-[1px] mx-auto bg-gradient-to-r from-transparent via-[#A1A1AA] to-transparent"
        ></div>
        <Numbers />
        <Footer />
        {/* <Publishers /> */}
        {/* <Reseller /> */}
      </div>
    </div>
  </div>
    
  </LanguageContext.Provider>
  )
}

export default App;

import React, { useEffect } from "react";
import {
  ubwidget,
  Sports,
  Weather,
  Game,
  Chatbot,
  Quiz,
  Caricature,
  Covid,
  Content,
  Universal,
  moreWidgets,
  Polls,
  Gold,
  webstory,
} from "../../constants";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import showmore from "../../Assets/show_more.png";
import back from "../../Assets/back.png";

function KnowMore(props) {
  const [show, setShow] = useState({ display: true, name: "" });
  const showComp = (name) => {
    setShow({ display: false, name: name });
  };
  const hideComp = (name) => {
    setShow({ display: true, name: name });
  };
  return (
    <>
      {moreWidgets.map((widget) => (
        <>
          <motion.div
            initial={{ opacity: 0, scale: 0.99 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0 }}
            onMouseEnter={() => showComp(widget.name)}
            onMouseLeave={() => hideComp(widget.name)}
            className={`bg-[#262626]  sm:flex hidden flex-col justify-between rounded-lg items-center box-border relative duration-1000`}
          >
            <div className="flex flex-col justify-center rounded-lg items-center box-border">
              <div className="pt-4">
                <img
                  src={widget.icon}
                  alt="widget icon"
                  className={`sm:w-[40px] lg:w-[100%]  animate-fade ${
                    !show.display &&
                    show.name === widget.name &&
                    show.name !== "More"
                      ? "hidden"
                      : "block"
                  }`}
                />
              </div>
              <div className="flex animate-fade">
                <p className={`text-center lg:pt-4 sm:pt-2`}>{widget.name}</p>
              </div>
            </div>
            <motion.div
              className={`animate-fade h-auto text-[12px] flex flex-1 pl-4 pr-2 justify-center items-center ${
                !show.display &&
                show.name === widget.name &&
                show.name !== "More"
                  ? "block"
                  : "hidden"
              }`}
              initial={{ opacity: 0, scale: 0.99 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <p className="text-center">
                {show.name.split(" ")[0] === "Sports"
                  ? Sports.content
                  : show.name.split(" ")[0] === "Weather"
                  ? Weather.content
                  : show.name.split(" ")[0] === "Game"
                  ? Game.content
                  : show.name.split(" ")[0] === "Chatbot"
                  ? Chatbot.content
                  : show.name.split(" ")[0] === "Caricature"
                  ? Caricature.content
                  : show.name.split(" ")[0] === "Covid"
                  ? Covid.content
                  : show.name.split(" ")[0] === "Content"
                  ? Content.content
                  : show.name.split(" ")[0] === "Universal"
                  ? Universal.content
                  : show.name.split(" ")[0] === "Quiz"
                  ? Quiz.content
                  : show.name.split(" ")[0] === "Polls"
                  ? Polls.content
                  : show.name.split(" ")[0] === "Gold"
                  ? Gold.content
                  : show.name.split(" ")[0] === "Web"
                  ? webstory.content
                  : ""}
              </p>
            </motion.div>
            <div
              className={`lg:mt-[3rem] ${!show.display ? "lg:mt-[0rem]" : ""} `}
            >
              {console.log("widget name", widget.name)}
              <a
                target="_blank"
                href={
                  widget.name === "Sports Widget"
                    ? "https://demos.unibots.com/cricketwidget_bowl.html"
                    : widget.name === "Covid Widget"
                    ? "https://demos.unibots.com/covidwidget.html"
                    : widget.name === "Caricature Widget"
                    ? "https://demos.unibots.com/cricketwidget_caricature.html"
                    : widget.name === "Chatbot Widget"
                    ? "https://demos.unibots.com/chatbot.html"
                    : widget.name === "Game Widget"
                    ? "https://demos.unibots.com/gamewidget.html"
                    : widget.name === "Weather Widget"
                    ? "https://demos.unibots.com/weatherwidget.html"
                    : widget.name === "Content Widget"
                    ? "https://demos.unibots.com/content_recommendation.html"
                    : widget.name === "Universal Widget"
                    ? "https://demos.unibots.com/univeralwidget.html"
                    : widget.name === "Quiz Widget"
                    ? "https://demos.unibots.com/quiz_banner300x250.html"
                    : widget.name === "Polls Widget"
                    ? "https://demos.unibots.in/cricket_pollWidget.html"
                    : widget.name === "Gold Price Widget"
                    ? "https://demos.unibots.in/golddata_cube.html"
                    : // : widget.name === "Triplex banner"
                      // ? "https://demos.unibots.in/chained_docked.html"
                      "https://unibots.com/"
                }
                rel="noreferrer"
              >
                <button className={`text-[12px] pb-2 cool-link `}>
                  {" "}
                  Quick Demo ->
                </button>
              </a>
            </div>
          </motion.div>
        </>
      ))}

      {/* mobile */}
      {moreWidgets.map((widget) => (
        <div className="bg-[#262626] sm:hidden flex text-white  justify-evenly rounded-xl items-center box-border h-[100px]">
          <div className="">
            <img src={widget.icon} alt="" className="w-[55px] p-1" />
          </div>
          <div className="flex flex-col justify-between gap-4">
            <p className="text-center text-[10px] pr-[2px]">{widget.name}</p>
            <a
              target="_blank"
              href={
                widget.name === "Sports Widget"
                  ? "https://demos.unibots.com/cricketwidget_bowl.html"
                  : widget.name === "Covid Widget"
                  ? "https://demos.unibots.com/covidwidget.html"
                  : widget.name === "Caricature Widget"
                  ? "https://demos.unibots.com/cricketwidget_caricature.html"
                  : widget.name === "Chatbot Widget"
                  ? "https://demos.unibots.com/chatbot.html"
                  : widget.name === "Game Widget"
                  ? "https://demos.unibots.com/gamewidget.html"
                  : widget.name === "Weather Widget"
                  ? "https://demos.unibots.com/weatherwidget.html"
                  : widget.name === "Content Widget"
                  ? "https://demos.unibots.com/content_recommendation.html"
                  : widget.name === "Universal Widget"
                  ? "https://demos.unibots.com/univeralwidget.html"
                  : widget.name === "Quiz Widget"
                  ? "https://demos.unibots.com/quiz_banner300x250.html"
                  : widget.name === "Polls Widget"
                  ? "https://demos.unibots.in/cricket_pollWidget.html"
                  : widget.name === "Gold Price Widget"
                  ? "https://demos.unibots.in/golddata_cube.html"
                  : // : widget.name === "Triplex banner"
                    // ? "https://demos.unibots.in/chained_docked.html"
                    "https://unibots.com/"
              }
              rel="noreferrer"
            >
              <button className="text-[10px]"> Quick Demo -></button>
            </a>
          </div>
        </div>
      ))}

      <div
        onClick={() => props.showChange()}
        className="border-[2px] border-gray-700 flex justify-center sm:gap-6 gap-0 sm:flex-col items-center rounded-xl cursor-pointer"
      >
        <img src={back} alt="back button" />
        <p>Back</p>
      </div>
    </>
  );
}

function UbWidgets() {
  const [show, setShow] = useState({ display: true, name: "" });
  const [showMore, setShowMore] = useState(false);
  const showChange = () => {
    setShowMore(false);
  };
  const showComp = (name) => {
    setShow({ display: false, name: name });
  };
  const hideComp = (name) => {
    setShow({ display: true, name: name });
  };

  const moreWidgetsfn = () => {
    console.log("more");
    setShowMore(true);
  };

  const { ref, inView } = useInView();
  const animation = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        y: ["20vh", "0vh"],
        transition: { ease: "easeOut", duration: 1 },
        duration: 1,
        bounce: 0.3,
      });
    }
  }, [inView]);

  return (
    <>
      <section
        ref={ref}
        className="sm:grid hidden lg:grid-cols-4 sm:grid-cols-2 lg:grid-rows-2 gap-4 px-6 py-4 w-[100%] h-full"
      >
        {showMore ? (
          <KnowMore showChange={showChange} />
        ) : (
          <>
            {ubwidget.map((widget, index) => (
              <motion.div
                initial={{ opacity: 0, scale: 0.99 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0 }}
                onMouseEnter={() => showComp(widget.name)}
                onMouseLeave={() => hideComp(widget.name)}
                className={`bg-[#262626] flex flex-col justify-between rounded-lg items-center box-border relative duration-1000`}
              >
                <div className="flex flex-col justify-center rounded-xl items-center box-border">
                  <div className="pt-4">
                    <img
                      src={widget.icon}
                      alt=""
                      className={`sm:w-[40px] lg:w-[100%]  animate-fade ${
                        !show.display &&
                        show.name === widget.name &&
                        show.name !== "More"
                          ? "hidden"
                          : "block"
                      }`}
                    />
                  </div>
                  <div className="flex animate-fade">
                    <p className={`text-center lg:pt-4 sm:pt-2`}>
                      {widget.name}
                    </p>
                  </div>
                </div>
                <motion.div
                  className={`animate-fade h-auto text-[12px] flex flex-1 pl-4 pr-2 justify-center items-center ${
                    !show.display &&
                    show.name === widget.name &&
                    show.name !== "More"
                      ? "block"
                      : "hidden"
                  }`}
                  initial={{ opacity: 0, scale: 0.99 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <p>
                    {show.name.split(" ")[0] === "Sports"
                      ? Sports.content
                      : show.name.split(" ")[0] === "Weather"
                      ? Weather.content
                      : show.name.split(" ")[0] === "Game"
                      ? Game.content
                      : show.name.split(" ")[0] === "Chatbot"
                      ? Chatbot.content
                      : show.name.split(" ")[0] === "Caricature"
                      ? Caricature.content
                      : show.name.split(" ")[0] === "Covid"
                      ? Covid.content
                      : show.name.split(" ")[0] === "Content"
                      ? Content.content
                      : show.name.split(" ")[0] === "Universal"
                      ? Universal.content
                      : show.name.split(" ")[0] === "Quiz"
                      ? Quiz.content
                      : show.name.split(" ")[0] === "Web"
                      ? webstory.content
                      : ""}
                  </p>
                </motion.div>
                <div
                  className={`lg:mt-[3rem] ${
                    !show.display ? "lg:mt-[0rem]" : ""
                  } `}
                >
                  <a
                    target="_blank"
                    href={
                      widget.name === "Sports Widget"
                        ? "https://demos.unibots.com/cricketwidget_bowl.html"
                        : widget.name === "Covid Widget"
                        ? "https://demos.unibots.com/covidwidget.html"
                        : widget.name === "Caricature Widget"
                        ? "https://demos.unibots.com/cricketwidget_caricature.html"
                        : widget.name === "Chatbot Widget"
                        ? "https://demos.unibots.com/chatbot.html"
                        : widget.name === "Game Widget"
                        ? "https://demos.unibots.com/gamewidget.html"
                        : widget.name === "Weather Widget"
                        ? "https://demos.unibots.com/weatherwidget.html"
                        : widget.name === "Content Widget"
                        ? "https://demos.unibots.com/content_recommendation.html"
                        : widget.name === "Universal Widget"
                        ? "https://demos.unibots.com/univeralwidget.html"
                        : widget.name === "Quiz Widget"
                        ? "https://demos.unibots.com/quiz_banner300x250.html"
                        : widget.name === "Web Stories"
                        ? "https://demos.unibots.in/webstories_widget.html"
                        : "https://unibots.in/"
                    }
                    rel="noreferrer"
                  >
                    <button className={`text-[12px] pb-2 cool-link `}>
                      {" "}
                      Quick Demo ->
                    </button>
                  </a>
                </div>
              </motion.div>
            ))}
            <div
              className="border- flex justify-center items-center flex-col sm:gap-6 cursor-pointer border-[2px] rounded-xl border-gray-700"
              onClick={() => moreWidgetsfn()}
            >
              <img src={showmore} alt="know more" />
              <h1>View More</h1>
            </div>
          </>
        )}
      </section>
      {/* mobile */}

      <section className="sm:hidden grid grid-cols-2 mt-4 mb-[2rem] gap-4">
        {showMore ? (
          <KnowMore showChange={showChange} />
        ) : (
          <>
            {ubwidget.map((widget) => (
              <div className="bg-[#262626] flex text-white  justify-evenly rounded-xl items-center box-border h-[100px]">
                <div className="">
                  <img src={widget.icon} alt="" className="w-[55px] p-1" />
                </div>
                <div className="flex flex-col justify-between gap-4">
                  <p className="text-center text-[10px] pr-[2px]">
                    {widget.name}
                  </p>
                  <a
                    target="_blank"
                    href={
                      widget.name === "Sports Widget"
                        ? "https://demos.unibots.com/cricketwidget_bowl.html"
                        : widget.name === "Covid Widget"
                        ? "https://demos.unibots.com/covidwidget.html"
                        : widget.name === "Caricature Widget"
                        ? "https://demos.unibots.com/cricketwidget_caricature.html"
                        : widget.name === "Chatbot Widget"
                        ? "https://demos.unibots.com/chatbot.html"
                        : widget.name === "Game Widget"
                        ? "https://demos.unibots.com/gamewidget.html"
                        : widget.name === "Weather Widget"
                        ? "https://demos.unibots.com/weatherwidget.html"
                        : widget.name === "Content Widget"
                        ? "https://demos.unibots.com/content_recommendation.html"
                        : widget.name === "Universal Widget"
                        ? "https://demos.unibots.com/univeralwidget.html"
                        : widget.name === "Quiz Widget"
                        ? "https://demos.unibots.com/quiz_banner300x250.html"
                        : widget.name === "Web Stories"
                        ? "https://demos.unibots.in/webstories_widget.html"
                        : "https://unibots.in/"
                    }
                    rel="noreferrer"
                  >
                    <button className="text-[10px]"> Quick Demo -></button>
                  </a>
                </div>
              </div>
            ))}
            <div
              className="flex justify-center items-center gap-2 flex-col cursor-pointer border-[2px] rounded-xl h-[100px] border-gray-700"
              onClick={() => moreWidgetsfn()}
            >
              <img src={showmore} width="50px" alt="know more" />
              <h1 className="text-[12px]">View More</h1>
            </div>
          </>
        )}
      </section>
    </>
  );
}

export default UbWidgets;

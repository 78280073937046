import React from "react";

function Player(props) {
  return (
    <div className="container">
      <div className="h-0 sm:mt-12 mt-[50%] pt-25px pb-[56.2%] relative">
        <button
          type="button"
          onClick={() => props.setClose(false)}
          class="absolute top-[-50px] right-0 inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out w-[120px]"
        >
          Close
        </button>
        <iframe
          className="absolute w-[100%] h-[100%]  rounded-xl shadow-xl shadow-slate-800"
          src={`https://www.youtube.com/embed/LbYfoy6c8fw?rel=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Unibots"
        />
      </div>
    </div>
  );
}

export default Player;

import React, {useState, useEffect} from 'react'
import { Dialog } from "@headlessui/react";
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput , { isValidPhoneNumber } from 'react-phone-number-input'
function GameContact() {
    const navigate = useNavigate();
    let [isOpen, setIsOpen] = useState(false);
    const [work, setWork] = useState(true);
    const [seller, setSeller] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [value, setValue] = useState()
    const [demoObj, setDemoObj] = useState({
      name: "",
      email: "",
      property: "",
      websiteNumber: "",
      skype: "",
      phone:"",
      pv: "",
     
    });
    const handleWork = () => {
      setWork(true);
      setSeller(false);
    };
    const handleSeller = () => {
      setWork(false);
      setSeller(true);
    };
  
    const data = ["1", "2", "3", "4"];
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselInfiniteScroll = () => {
      if (currentIndex === data.length - 1) {
        return setCurrentIndex(0);
      }
      return setCurrentIndex(currentIndex + 1);
    };
  
    useEffect(() => {
      const interval = setInterval(() => {
        carouselInfiniteScroll();
      }, 5000);
  
      return () => clearInterval(interval);
    })
    const discord = () => {
        const whurl = process.env.REACT_APP_GAME_QUIZ;
      
        const msg = {
          content: 
         `
          **Client Name**: ${demoObj.name || "NA"}
          **Email/Skype**: ${demoObj.email || "NA"}
          **Website**: ${demoObj.property || "NA"}
          **Phone**: ${demoObj.phone || "NA"}
          **Page Visits**: ${demoObj.pv || "NA"}`,
        };
      
        fetch(whurl + "?wait=true", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(msg),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            // Check if the response contains expected data indicating success
            if (data && data.id) {
              console.log('Discord notification sent successfully');
            } else {
              console.log('Failed to send Discord notification');
              alert("Something Went Wrong")
            }
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
      };
      
  
    // const handleSend = async () => {
    //   try {
    //     await axios.post(`http://localhost:4000/send_mail`, {
    //       text: demoObj,
    //     });
    //   } catch (error) {
    //     console.log(error);
    //     alert("error occured");
    //   }
    // };
  
    useEffect(() => {
      // console.log(formErrors);
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        setIsOpen(true);
        discord();
        setTimeout(() => {
            setDemoObj({
                name: "",
                email: "",
                property: "",
                websiteNumber: "",
                skype: "",
                pv: "",
                phone:""
              })
            setIsOpen(false);
            navigate('/quizgame')
          }, 7000);
      }
  
      // return () => {
      //   clearTimeout(interval)
      // }
    }, [formErrors]);
  
    const handleSubmit = (e) => {
      setFormErrors(validate(demoObj));
      setIsSubmit(true);
    };
  
    const validate = (values) => {
        console.log("values", values)
      const error = {};
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!values.name) {
        error.name = "Name required";
      }
    
      if (!values.phone) {
        error.phone = "Phone number with country code required";
      } else if (!isValidPhoneNumber(values.phone)) {
        error.phone = "Invalid phone number";
      }
    
    //   if (!values.email) {
    //     error.email = "Email required";
    //   } else if (!regex.test(values.email)) {
    //     error.email = "Enter a valid Email";
    //   }
      if (!values.property && !values.websiteNumber) {
        error.property = "Website/App Url is required";
      }
      // if(!values.country) {
      //   error.country = "Please tell us where are you From?"
      // }
      // if (!values.pv && !values.websiteNumber) {
      //   error.pv = "Please enter Your Daily Page Views";
      // }
      console.log(error)
      return error;
    };
  
    const handleChange = (e) => {
      console.log("event", e)
      setDemoObj((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    };
    const handlePhoneChange = (phone) => {
      console.log("phone", phone)
      setDemoObj((prevState) => ({
        ...prevState,
        phone,
      }));
    };
  return (
    <div className="flex justify-center h-screen  bg-primary w-full ">
         <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="relative z-[9999999999]"
          >
            <div
              className="fixed inset-0 backdrop-blur-sm bg-black/50"
              aria-hidden="true"
            >
              <div className="fixed inset-0 flex items-center justify-center w-full m-auto">
                <Dialog.Panel className="mx-auto w-[500px] rounded bg-white p-[2rem]">
                  <Dialog.Title className="font-bold mb-2 text-[24px]">
                    Thank you for getting in touch!
                  </Dialog.Title>
                  <p>
                    We appreciate you contacting Unibots. One of our Team Member
                    will get back in touch with you soon!
                  </p>
                  {/* ... */}
                </Dialog.Panel>
              </div>
            </div>
          </Dialog>
    
      <div className="flex items-center justify-center  order-1 sm:h-screen ">
      <div className="lg:m-14 pt-6 md:m-8 my-4 text-white bg-[#27272A] flex-auto lg:mt-6 p-6 rounded-2xl order-1 lg:order-2">
                  <div className="text-center  flex gap-6 flex-col items-center">
                    {/* <div className="text-white sm:w-[100%] w-full mx-auto flex flex-row justify-around sm:my-[2rem] bg-[#3F3F46] h-auto rounded-full">
                      <div
                        className={`rounded-full w-full font-normal text-center p-2 sm:py-3 text-[13px]   sm:text-[16px]  ${
                          work ? "bg-blue-600" : ""
                        }  ${
                          work ? "text-white  font-bold p-2" : ""
                        } cursor-pointer`}
                        onClick={handleWork}
                      >
                        Work with unibots
                      </div>
                      <div
                        className={`rounded-full w-full text-center font-normal p-2 sm:py-3 text-[14px] sm:text-[16px]  ${
                          seller ? "bg-blue-600" : ""
                        }  ${
                          seller ? "text-white  font-bold p-2" : ""
                        } cursor-pointer`}
                        onClick={handleSeller}
                      >
                        Become a reseller
                      </div>
                    </div> */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 w-full">
                      <div className='w-full'>
                        <input
                          className="border w-full rounded-lg bg-[#27272A] py-3 px-5"
                          name="name"
                          value={demoObj.name}
                          placeholder="Your Name"
                          id="name"
                          onChange={handleChange}
                          required
                        />
                        <span className="text-[14px] block sm:inline text-red-700 font-normal">
                          {formErrors.name}
                        </span>
                      </div>
                      <div>
                        <input
                          className="border w-full rounded-lg bg-[#27272A] py-3 px-5"
                          name="email"
                          id="email"
                          value={demoObj.email}
                          placeholder="Your Email / Skype"
                          onChange={handleChange}
                          required
                        />
                        <span className="text-[14px] block sm:inline text-red-700 font-normal">
                          {formErrors.email}
                        </span>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 w-full">
                      <div>
                        {work ? (
                          <>
                            <input
                              className="border w-full rounded-lg py-3 px-5 bg-[#27272A]"
                              name="property"
                              value={demoObj.property}
                              placeholder="Your Website/App URL"
                              id="property"
                              onChange={handleChange}
                              required
                            />
                            <span className="text-[14px] text-red-700 font-normal">
                              {formErrors.property}
                            </span>
                          </>
                        ) : (
                          <input
                            className="border w-full rounded-lg py-3 px-5 bg-[#27272A]"
                            name="websiteNumber"
                            value={demoObj.websiteNumber}
                            placeholder="How many Websites/Apps you are working with"
                            id="property"
                            onChange={handleChange}
                            required
                            type="number"
                          />
                        )}
                      </div>
                      <div>
                      <PhoneInput
                          className="border w-full rounded-lg py-[2px] bg-[#27272A]"
                          name="phone"
                          id="phone"
                          value={demoObj.phone}
                          placeholder="Your Phone No."
                          onChange={handlePhoneChange}
                        />
                      <span className="text-[14px] block sm:inline text-red-700 font-normal">
                          {formErrors.phone}
                        </span>
                      </div>
                    </div>
                    {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-5  w-full"> */}
                      {/* <div>
                        <input
                          className="border w-full rounded-lg py-3 px-5 bg-[#27272A]"
                          name="country"
                          value={demoObj.country}
                          placeholder="Your Country"
                          id="country"
                          onChange={handleChange}
                        />
                      </div> */}
                      {/* <div>
                        <input
                          className="border w-full rounded-lg py-3 px-5 bg-[#27272A]"
                          name="phone"
                          id="phone"
                          value={demoObj.phone}
                          placeholder="Your Phone No."
                          onChange={handleChange}
                        />
                      </div> */}
                    {/* </div> */}

                    <div
                      className={`grid grid-cols-1 ${
                        work ? "sm:grid-cols-2" : ""
                      } gap-5 w-full`}
                    >
                      <div>
                        {work ? (
                          <>
                            <input
                              className="border w-full rounded-lg py-3 px-5 bg-[#27272A]"
                              name="pv"
                              value={demoObj.pv}
                              placeholder="Your Daily Page Views"
                              id="pv"
                              onChange={handleChange}
                              required
                            />
                            <span className="text-[14px] text-red-700 font-normal">
                              {formErrors.pv}
                            </span>
                          </>
                        ) : (
                          <>
                            <textarea
                              rows=""
                              className="border w-[90%] sm:w-full rounded-lg py-3 px-5 bg-[#27272A]"
                              name="why"
                              value={demoObj.why}
                              placeholder="Why do you want to be a unibots Reseller"
                              id="why"
                              onChange={handleChange}
                            />
                          </>
                        )}
                      </div>
                      {/* {toggle ? (
                      <input
                        className="border rounded-lg py-3 px-5 bg-[#27272A]"
                        name="pv"
                        value={demoObj.pv}
                        placeholder="Additional Input Field"
                        id="pv"
                        onChange={handleChange}
                      />
                    ) : null} */}
                    </div>

                    <div className="items-center  py-3 flex w-full gap-5">
            
                      <button
                        onClick={() => handleSubmit()}
                        className="text-[12px] sm:text-[16px] w-full h-[50px] inline-flex gap-2 items-center justify-center px-[20px] py-[14px] textase font-medium leading-6 text-white whitespace-no-wrap bg-blue-600 border border-blue-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        style={{
                          boxShadow: "0px 6px 20px rgba(0, 122, 255, 0.3)",
                        }}
                      >
                        {work ? "Get Started" : "Let's Go"}
                      </button>
                      <div className='text-white w-[100%]'>
            {/* <a href="https://quiztwiz.com/" target="_blank" rel="noreferrer"> */}
              {/* <button className="p-2 border-2 rounded-xl w-full h-[50px] focus:ring-4 focus:outline-none focus:ring-purple-200" onClick={() => window.open("https://quiztwiz.com/")}>
                
                Quiz Demo
              </button> */}
            {/* </a> */}
            </div>
                    </div>
                  </div>
                </div>
      </div>
    </div>
  )
}

export default GameContact
import React, { useEffect, useState, useRef } from "react";
import styles from "../../style";
import { ChevronRightIcon } from "@heroicons/react/solid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Accordion, createStyles } from "@mantine/core";
import { product, productIntro } from "../../constants";
import { Disclosure, Transition } from "@headlessui/react";
import UbWidgets from "./UbWidgets";
import UbVideos from "./UbVideos";
import UbBanner from "./UbBanner";
import PrebitAdapter from "./PrebitAdapter";
import Ubheader from "./Ubheader";
import Ubapp from "./Ubapp";
import Ubmanage from "./Ubmanage";
import Ubquiz from "./Ubquiz";
import Ubnative from "./Ubnative";

const useStyles = createStyles((theme) => ({
  item: {
    backgroundColor: "#1f2937",
    marginBottom: "1rem",
    "&[data-active]": {
      backgroundColor: "#181818",
      padding: "0px",
    },
  },
  item2: {
    backgroundColor: "white",
    "&[data-active]": {
      backgroundColor: "white",
    },
  },
}));

function Products() {
  const [prod, setProd] = useState("UB Widgets");
  const [active, setActive] = useState(true);
  const [open, setOpen] = useState(false);
  const [first, setFirst] = useState(true);
  const { classes } = useStyles();
  const handleClick = (name, idx) => {
    setProd(name);
    setActive(true);
    setOpen((prev) => !prev);
  };

  const buttonRefs = useRef([]);
  const openedRef = useRef(null);

  const clickRecent = (name, index) => {
    const clickedButton = buttonRefs.current[index];

    if (clickedButton === openedRef.current) {
      openedRef.current = null;
      setFirst(false);
      return;
    }
    if (Boolean(openedRef.current?.getAttribute("data-value"))) {
      openedRef.current?.click();
    }
    openedRef.current = clickedButton;
  };

  const handleMoblie = (name, idx) => {
    if (prod !== name) {
      setProd(name);

      setActive(true);
      setOpen((prev) => !prev);
    }
    clickRecent(name, idx);
  };

  return (
    <section
      id="Solutions"
      className="w-full text-white bg-primary h-auto relative sm:mt-[6rem] sm:pt-[4.5rem] "
    >
      {/* <div className="absolute h-[586px] w-[541px] top-[20%] right-0 z-0">
        <img src={unibots} alt="" />
      </div> */}
      {/* <div className="absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient" /> */}
      {/* <div className="absolute z-[0] w-[40%] h-[60%] -left-[50%] rounded-full blue__gradient" /> */}
      <div className="flex justify-between ">
        <div className="flex flex-col">
          <h1 className="font-semibold xs:text-[36px] sm:text-[24px] text-[20px] text-white">
            {productIntro.heading}
          </h1>
          <p
            className={`${styles.paragraph} text-[#A1A1AA] text-[14px] sm:text-[16px] w-full sm:mt-2 mt-2 mb-6 sm:mb-[4rem]`}
          >
            {productIntro.content}
          </p>
        </div>
        {/* <div>
          <button className="text-white hidden sm:bold z-10 py-[14px] px-[20px] bg-blue-600 w-[210px] font-medium text-xs leading-tight rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
            Get a Demo
          </button>
        </div> */}
      </div>

      {/* producsts mobile */}
      <div className="sm:hidden relative w-full lg:max-w-sm" close="true">
        {/* <Accordion variant="separated" defaultValue="customization">
      <Accordion.Item value="customization">
        <Accordion.Control>Customization</Accordion.Control>
        <Accordion.Panel>Colors, fonts, shadows and many other parts are customizable to fit your design needs</Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="flexibility">
        <Accordion.Control>Flexibility</Accordion.Control>
        <Accordion.Panel>Configure components appearance and behavior with vast amount of settings or overwrite any part of component styles</Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="focus-ring">
        <Accordion.Control>No annoying focus ring</Accordion.Control>
        <Accordion.Panel>With new :focus-visible pseudo-class focus ring appears only when user navigates with keyboard</Accordion.Panel>
      </Accordion.Item>
    </Accordion> */}
        <Accordion
          variant="filled"
          radius="lg"
          defaultValue="UB Widgets"
          classNames={{ item: classes.item }}
        >
          {product.map((button, idx) => (
            <div>
              <Accordion.Item value={button.name}>
                <Accordion.Control
                  className={{ item: classes.item2 }}
                  sx={{ color: "white" }}
                >
                  {button.name}
                </Accordion.Control>
                <Accordion.Panel
                  className={{ item: classes.item }}
                  sx={{
                    borderTop: "1px solid #A1A1AA",
                    borderBottom: "1px solid #A1A1AA",
                    borderRadius: "16px",
                  }}
                >
                  {button.name === "UB Widgets" ? (
                    <UbWidgets />
                  ) : button.name === "UB Videos" ? (
                    <UbVideos />
                  ) : button.name === "UB Banner Ads" ? (
                    <UbBanner />
                  ) : button.name === "Prebid Adapter" ? (
                    <PrebitAdapter />
                  ) : button.name === "UB Header Bidding" ? (
                    <Ubheader />
                  ) : button.name === "UB App Monetize" ? (
                    <Ubapp />
                  ) : button.name === "UB Managed Account" ? (
                    <Ubmanage />
                  ) : button.name === "UB Quiz/Gaming" ? (
                    <Ubquiz />
                  ) : button.name === "UB Native 360" ? (
                    <Ubnative />
                  ) : (
                    <></>
                  )}
                </Accordion.Panel>
              </Accordion.Item>

              {/* <Disclosure
              key={button.id}
              as="div"
              className={`my-4`}
              // defaultOpen={button.name === "UB Widgets" && first ? true : false}
              // defaultOpen={false}
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    data-value={open}
                    ref={(ref) => {
                      buttonRefs.current[idx] = ref;
                    }}
                    className={`${
                      prod === button.name && active
                        ? "bg-white text-black"
                        : "bg-gray-800"
                    }  py-2 px-2  rounded-xl w-full flex justify-between`}
                    onClick={() => handleMoblie(button.name, idx)}
                  >
                    {button.name}
                    <ChevronRightIcon
                      className={`${
                        (prod === button.name && active && open) || open
                          ? "rotate-90"
                          : "rotate-0"
                      }   w-[24px] h-[24px]`}
                    />
                  </Disclosure.Button>
                  <Transition
                    show={open}
                    enter="transition duration-1000 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-1000 opacity-100"
                    leave="transition duration-100 ease-out"
                    leaveFrom="transform scale-1000 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="text-gray-500 mt-2">
                      {button.name === "UB Widgets" ? (
                        <UbWidgets />
                      ) : button.name === "UB Videos" ? (
                        <UbVideos />
                      ) : button.name === "UB Banner Ads" ? (
                        <UbBanner />
                      ) : button.name === "Prebid Adapter" ? (
                        <PrebitAdapter />
                      ) : button.name === "UB Header Bidding" ? (
                        <Ubheader />
                      ) : button.name === "UB App Monetize" ? (
                        <Ubapp />
                      ) : button.name === "UB Managed Account" ? (
                        <Ubmanage />
                      ) : button.name === "UB Quiz/Gaming" ? (
                        <Ubquiz />
                      ) : button.name === "UB Native 360" ? (
                        <Ubnative />
                      ) : (
                        <></>
                      )}
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure> */}
            </div>
          ))}
        </Accordion>
      </div>

      {/* products */}
      <div className="sm:flex text-white justify-between w-full hidden">
        {/* product buttons */}

        <div className="flex flex-col w-1/3 lg:gap-2">
          {product.map((button, idx) => (
            <div
              key={idx}
              className={`${
                prod === button.name && active ? "bg-white" : ""
              } flex my-[px] cursor-pointer justify-center items-center w-full rounded-lg`}
            >
              <div
                onClick={() => handleClick(button.name, idx)}
                className="flex justify-between hover:bg-[#ffffff40] rounded-xl items-center w-[100%]"
              >
                <div>
                  <button
                    className={` ${
                      prod === button.name && active
                        ? "text-black font-bold"
                        : "text-white"
                    } text-white text-[20px] h-full w-full md:font-medium md:text-[14px] lg:text-[16px] sm:text-[14px]  rounded-lg text-sm px-5 md:py-[14px] py-[8px] text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2`}
                  >
                    {button.name}
                  </button>
                </div>
                <div
                  className={`${
                    prod === button.name && active ? "text-black" : "text-white"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* product discription */}
        <div className="w-[100%] h-[528px] rounded-2xl ml-[2rem] z-10 pl-[2rem] border-l-2">
          {prod === "UB Widgets" && <UbWidgets />}
          {prod === "UB Videos" && <UbVideos />}
          {prod === "UB Banner Ads" && <UbBanner />}
          {prod === "Prebid Adapter" && <PrebitAdapter />}
          {prod === "UB Header Bidding" && <Ubheader />}
          {prod === "UB App Monetize" && <Ubapp />}
          {prod === "UB Managed Account" && <Ubmanage />}
          {prod === "UB Quiz/Gaming" && <Ubquiz />}
          {prod === "UB Native 360" && <Ubnative />}
        </div>
      </div>
    </section>
  );
}

export default Products;

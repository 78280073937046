import React from 'react'
import {phoneicon,quiztwiz_icon,gametub_icon,new_back ,ubicon,unibots_name } from "../../Assets";
import { useNavigate } from 'react-router-dom';

function Arbitrage() {
    const navigate = useNavigate();
  return (
    <div className='bg-gray-900 text-white min-h-screen p-8 sm:px-16 px-10' 
    style={{ backgroundImage: `url(${new_back})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
    <header className="flex justify-between items-center mb-8">
      <div className="flex justify-between items-center w-full">
        <div className="sm:w-[40px] w-full">
            <img src={ubicon} />
        </div>
        <div className="ml-2 text-xl font-bold hidden sm:block">
            <img src={unibots_name} className='w-[200px]' />
        </div>
      </div>
    </header>
    
    <main className="mx-auto flex sm:flex-row flex-col">
        <div className='flex-1 flex flex-col justify-center'>

      <h1 className="text-4xl font-bold text-[#007AFF] mb-4">Are you an Ad Arbitrager?</h1>
      <p className="text-2xl mb-8 text-[#E4E4E4]">Boost your Revenues With Our Innovative Quiz and Gaming Platforms</p>
      
       
      <div className=" mb-8">
        <p className="text-lg mb-4 text-[#D8D8D8]">Unlock High CPM and Premium Demand via Adsense for Games</p>
       
        <button className="bg-blue-500 text-white px-6 py-2 mt-4 rounded-lg hover:bg-blue-600 my-8"
         onClick={() => navigate('/contactus')}
        >Contact Us</button>
      </div>
      <div className="gap-4 mb-4 flex flex-col">
            <div className='flex gap-4'>
        <img src={quiztwiz_icon} className='w-24 h-6' />
          <a href="https://defaultnew.quiztwiz.com/" className="text-blue-400 hover:underline text-lg">
          Demo ↗
            </a>
            </div>
            <div className='flex gap-4'>
            <img src={gametub_icon} className='w-auto h-6' />
          <a href="https://thegametub.com/" className="text-blue-400 hover:underline">Demo ↗</a>
            </div>
        </div>
      </div>
      <div className="flex-1 mb-8">
        {/* Replace these divs with actual image components */}
     <img src={phoneicon} />
      </div>
     
    </main>
      {/* <div className="grid sm:grid-cols-3 grid-cols-1 gap-4 text-center sm:w-[70%]">
        <div className="text-gray-400 ">Customizable quizzes for maximum engagement</div>
        <div className="text-gray-400 ">Access to high-paying advertisers</div>
        <div className="text-gray-400 ">Premium AdSense for Games: High CPMs Guaranteed</div>
      </div> */}
  </div>
  );
}

export default Arbitrage;
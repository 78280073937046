import React, { useEffect } from "react";
import { videoImg } from "../../Assets";
import { ubvideos } from "../../constants/index";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function UbVideos() {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const imgAn = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        transition: { duration: 0.5 },
      });
      imgAn.start({
        x: ["10vh", "0vh"],
        transition: { ease: "easeOut", duration: 1.5 },
        duration: 1,
        bounce: 0.3,
      });
    }
  }, [inView]);
  return (
    <>
      <div
        ref={ref}
        className="sm:flex hidden justify-between md:flex-row sm:flex-col items-center h-full"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col h-full justify-around pl-4"
        >
          <div className="flex flex-col gap-6">
            <h1 className="text-[24px]">{ubvideos.heading}</h1>
            <h3 className="text-[18px]">{ubvideos.subHeading}</h3>
            <p className="text-[14px] max-w-[400px] text-[#A1A1AA]">
              {ubvideos.content}
            </p>
          </div>
          <div className="sm:flex justify-start lg:hidden">
            <img
              src={videoImg}
              alt="video"
              className=" object-contain w-[300px]"
            />
          </div>
          <div>
            <a href="http://video.unibots.com/" target="_blank" rel="noreferrer">
              <button className="p-2 border-2 rounded-xl w-[210px] h-[50px]">
                Quick demo
              </button>
            </a>
          </div>
        </motion.div>
        {/* image */}
        <motion.div
          initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="lg:flex flex-1 justify-end sm:hidden"
        >
          <img src={videoImg} alt="video" />
        </motion.div>
      </div>

      {/* mobile */}
      <div className="sm:hidden mt-4 mb-[2rem]">
        <div className="flex items-stretch h-full p-2">
          <div className="flex flex-col justify-between mt-2">
            <div className="flex flex-col items-stretch gap-4">
              <h1 className="text-[16px] text-white">{ubvideos.heading}</h1>
              <h3 className="text-[12px]">{ubvideos.subHeading}</h3>
              {/* <p className='text-[14px] max-w-[500px] text-[#A1A1AA]'>Unibots’ AI driven video player is powered with leading direct and programmatic advertisers. Increase your Ad Revenues upto 60% with exceptional CPMs and Fill Rates. UB Player has unique features like Auto Youtube Mapping, AI Video Content to serve most exciting video content to your users. We generate at least USD 0.75 to as high as USD 30 per pageview. Sit Back & Enjoy the Video Ad Revenues.</p> */}
            </div>
          </div>
          {/* image */}
          <div className="w-[80%]">
            <img src={videoImg} alt="video" />
          </div>
        </div>
        <p className="text-[12px] px-2 max-w-[500px] text-[#A1A1AA]">
          {ubvideos.content}
        </p>
        <a href="http://video.unibots.com/" target="_blank" rel="noreferrer">
          <button className="px-4 ml-2 leading-[22px] py-2 text-[14px] mt-[1rem] text-white rounded-lg border-2 border-white">
            Quick Demo
          </button>
        </a>
      </div>
    </>
  );
}

export default UbVideos;

import { useEffect, useState, useContext } from "react";
import { ubIcon, ubText } from "../../Assets";
import { navLinks, CDN_LINK } from "../../constants/index";
import Modal from "./ModalDemo";

import Demo from "../Forms/Demo";
import { LanguageContext } from "../../Context/LangContext";
const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [modalOpen, setIsModalOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const handleModal = (id) => {
    if (id === "4") {
      // console.log("-----id4----");
      setIsModalOpen(true);
    }
  };
  const check = useContext(LanguageContext);
  // console.log(check)
  let sitelang = check.lang;
  // console.log("sitelang - ", sitelang)
  const handleChange = (e) => {
    check.changeLang(e.target.value);
  };

  return (
    <>
      <nav
        id="Nav"
        className="w-full z-50 flex  py-6 justify-between items-center navbar"
      >
        {modalOpen && <Modal setIsModalOpen={setIsModalOpen} />}
        <div className="flex gap-4 w-full sm:w-full  justify-center sm:justify-start sm:flex">
          <img src={ubIcon} alt="bank" />
          <img src={ubText} alt="unibots" className="pb-[4px]" />
        </div>
        <ul
          className="list-none md:flex hidden
          justify-end items-center w-full"
        >
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              onClick={() => handleModal(nav.id)}
              className={`shrink-0 cool-link w-full md:w-fit font-poppins font-normal cursor-pointer text-[16px] text-white  ${
                index === navLinks.length - 1
                  ? "mr-0 backdrop-blur-md bg-white/20  px-[20px] py-[14px] rounded-lg"
                  : "mr-10"
              }`}
            >
              <a href={`#${nav[sitelang]}`}>{nav[sitelang]}</a>
            </li>
          ))}
        </ul>
        {/* <select
          className="custom-select h-[2rem] ml-4 pl-4"
          onChange={(e) => handleChange(e)}
        >
          <option value="english">English</option>
          <option value="indonesian">Indonesian</option>
        </select> */}
        {/* List for mobile devices */}
        <div className="lg:hidden flex flex-1 justify-end items-center">
          {/* <img src={toggle ? close : menu} 
            alt="menu" className="w-[28px] h-[28px] object-contain" onClick={() => setToggle((prev) => !prev)}
            /> */}
          {modalOpen && <Modal setIsModalOpen={setIsModalOpen} />}
          <div
            className={`${
              toggle ? "flex" : "hidden"
            } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
          >
            <ul
              className="list-none flex flex-col
                justify-end items-center
                "
            >
              {navLinks.map((nav, index) => (
                <li
                  key={nav.id}
                  onClick={() => handleModal(nav.id)}
                  className={`font-poppins font-normal cursor-pointer text-[16px] text-white ${
                    index === navLinks.length - 1 ? "mr-0" : "mb-4"
                  }`}
                >
                  <a href={`#${nav[sitelang]}`}>{nav[sitelang]}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import React, { useState, useEffect } from "react";
import styles from "../../style";
import { feedback, feedbackTitle } from "../../constants";
import FeedbackCard from "./FeedbackCard";
import { unibots } from "../../Assets";
import { vector } from "../../Assets";
function Testimonials() {
  const data = ["1", "2", "3", "4"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselInfiniteScroll = () => {
    if (currentIndex === data.length - 1) {
      return setCurrentIndex(0);
    }
    return setCurrentIndex(currentIndex + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      carouselInfiniteScroll();
    }, 15000);

    return () => clearInterval(interval);
  });

  return (
    <section
      id="clients"
      className={` ${styles.flexCenter} sm:mt-0  flex-col relative`}
    >
      {/* <div className="absolute h-[786px] w-[541px] top-[15%] right-0 z-0">
        <img src={unibots} alt="" />
        </div> */}

      <div className="absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient" />

      <div className="w-full flex justify-between items-center flex-col sm:mb-8 relative z-[1]">
        <h1
          className={`md:${styles.heading2} leading-[2] text-[20px] sm:text-[24px]`}
        >
          {feedbackTitle.heading}
        </h1>
        <div className="w-full md:mt-0 sm:mt-6 sm:mb-0 mt-2 mb-6">
          <p
            className={`${styles.paragraph}  text-[14px] sm:text-[16px] text-[#A1A1AA] text-left `}
          >
            {feedbackTitle.content}
          </p>
        </div>
      </div>
      <div className="sm:grid-cols-2 sm:grid hidden grid-flow-row w-full feedback-container relative z-[1]">
        {feedback.map((card, index) => (
          <FeedbackCard key={card.id} {...card} index={index} />
        ))}
      </div>

      {/* mobile */}
      <div className="carousel-container sm:hidden w-full mt-[10px]">
        {feedback.map((card, index) => {
          return (
            <>
              <div
                className={`flex gqp-4 w-[100%] carousel-item border-red-500`}
                style={{ transform: `translate(-${currentIndex * 100}%)` }}
              >
                <div className="w-[100%]">
                  <img
                    src={vector}
                    alt="double_quotes"
                    className="w-[60px] h-[60px] object-contain"
                  />
                  <p className="text-[#A1A1AA] text-[14px]">{card.content}</p>
                  <div className="flex flex-row justify-between items-end">
                    <div className="flex flex-col">
                      <h4 className="font-poopins font-semibold text-[16px] leading[32px] mt-2 text-white ">
                        {card.name}
                      </h4>
                      <p className="font-poopins font-normal text-[14px] leading[24px] text-[#A1A1AA] ">
                        {card.designation}
                      </p>
                      <p className="font-poopins font-normal text-[12px] leading[24px] text-[#A1A1AA] ">
                        {card.company}
                      </p>
                    </div>
                    <img
                      src={card.img}
                      alt={card.name}
                      className=" rounded-full  items-end"
                    />
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </section>
  );
}

export default Testimonials;

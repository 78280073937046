import webstories from "./webstories.png";
const CDN_LINK = "https://ubweb.b-cdn.net/Assets/";
let ubIcon = CDN_LINK + "nav/ub_icon.svg";
let ubText = CDN_LINK + "nav/ub_text_tm.svg";
let closeBtn = CDN_LINK + "nav/closeBtn.svg";
let heroImg = CDN_LINK + "home/hero_image.png";
let heroBack = CDN_LINK + "home/background_image.png";
let rocket = CDN_LINK + "home/rocket.svg";
let play = CDN_LINK + "home/play.svg";
let cloud = CDN_LINK + "why/Cloud.png";
let data = CDN_LINK + "why/Data.png";
let priceTag = CDN_LINK + "why/PriceTag.png";
let pieChart = CDN_LINK + "why/PieChart.png";
let rocketWhy = CDN_LINK + "why/Rocket.png";
let steps = CDN_LINK + "why/StepsChart.png";
let unibots = CDN_LINK + "unibots_ub.png";
let across33 = CDN_LINK + "partners/33across.png";
let google = CDN_LINK + "partners/google.png";
let magnite = CDN_LINK + "partners/magnite.png";
let amazon = CDN_LINK + "partners/amazon.png";
let nobid = CDN_LINK + "partners/nobid.png";
let openx = CDN_LINK + "partners/openx.png";
let outbrain = CDN_LINK + "partners/outbrain.png";
let pubmatic = CDN_LINK + "partners/pubmatic.png";
let global = CDN_LINK + "global.png";
let sovrn = CDN_LINK + "partners/sovrn.png";
let xandar = CDN_LINK + "partners/xandr.png";
let abp = CDN_LINK + "pubs/abp.png";
let ca = CDN_LINK + "pubs/cricket.png";
let d = CDN_LINK + "pubs/detik.png";
let e = CDN_LINK + "pubs/express.png";
let jagran = CDN_LINK + "pubs/jagran.png";
let mano = CDN_LINK + "pubs/manorama.png";
let oneindia = CDN_LINK + "pubs/oneindia.png";
let pinkvilla = CDN_LINK + "pubs/pink.png";
let print = CDN_LINK + "pubs/print.png";
let sakshi = CDN_LINK + "pubs/sakshi.png";
let tie = CDN_LINK + "pubs/indianexp.png";
let zee = CDN_LINK + "pubs/zee.png";
let corover = CDN_LINK + "testimonials/corover.png";
let docbao = CDN_LINK + "testimonials/docbao.png";
let erelago = CDN_LINK + "testimonials/erelago.png";
let patrika = CDN_LINK + "testimonials/patrika.png";
let vector = CDN_LINK + "testimonials/Vector.png";
let caricature_widget = CDN_LINK + "ubwidget/caricature_widget.png";
let content_widget = CDN_LINK + "ubwidget/content_widget.png";
let quiz = CDN_LINK + "ubwidget/quiz.png";
let cricket_widget = CDN_LINK + "ubwidget/cricket_widget.png";
let covid_widget = CDN_LINK + "ubwidget/covid_widget.png";
let newsbot = CDN_LINK + "ubwidget/newsbot.png";
let quiz_widget = CDN_LINK + "ubwidget/quiz_widget.png";
let uni_widget = CDN_LINK + "ubwidget/uni_widget.png";
let weather_widget = CDN_LINK + "ubwidget/weather_widget.png";
let videoImg = CDN_LINK + "videoImg.png";
let account_manage = CDN_LINK + "productImg/account_manage.png";
let app_game = CDN_LINK + "productImg/app_game.png";
let app_monetize = CDN_LINK + "productImg/app_monetize.png";
let banner = CDN_LINK + "productImg/banner.png";
let headerbidding = CDN_LINK + "productImg/headerbidding.png";
let native_ads = CDN_LINK + "productImg/native_ads.png";
let prebid_adapter = CDN_LINK + "productImg/prebid_adapter.png";
let hero_bg = CDN_LINK + "hero_bg.png";
let new_back = CDN_LINK + "new_back.jpg";
let sep = CDN_LINK + "sep.svg";
let mobilebg = CDN_LINK + "mobile_bg.png";
let service = CDN_LINK + "why/service.png";
let widgets = CDN_LINK + "why/widgets.png";
let certi = CDN_LINK + "certi.png";
let trustpilot = CDN_LINK + "trustpilot.png";
let amarujala = CDN_LINK + "pubs/amarujala.png";
let show_more = CDN_LINK + "show_more.png";
let moneycontrol = CDN_LINK + "moneycontrol.png";
let republicworld = CDN_LINK + "republicworld.png";
let news18 = CDN_LINK + "news18.png";
let trainman = CDN_LINK + "trainman.png";
let bharatpe = CDN_LINK + "pubs/bharatpe.png";
let polls_widget = CDN_LINK + "ubwidget/polls_widget.png";
let goldprice_widget = CDN_LINK + "ubwidget/goldprice_widget.png";
let triplex_widget = CDN_LINK + "ubwidget/triplex_widget.png";
let phoneicon = CDN_LINK +"quizgame_mob.png"
let quiztwiz_icon = CDN_LINK +"quiztwiz_icon.png"
let gametub_icon = CDN_LINK +"gametub_icon.png"
let ubicon = CDN_LINK + "ubicon.png"
let unibots_name = CDN_LINK + "unibots_name.png"
export {
  unibots_name,
  new_back,
  phoneicon,
  triplex_widget,
  gametub_icon,
  ubicon,
  quiztwiz_icon,
  polls_widget,
  goldprice_widget,
  bharatpe,
  moneycontrol,
  trainman,
  news18,
  republicworld,
  show_more,
  amarujala,
  trustpilot,
  certi,
  service,
  widgets,
  mobilebg,
  closeBtn,
  sep,
  videoImg,
  hero_bg,
  ubIcon,
  unibots,
  ubText,
  heroImg,
  heroBack,
  rocket,
  play,
  cloud,
  data,
  priceTag,
  pieChart,
  rocketWhy,
  steps,
  across33,
  google,
  magnite,
  nobid,
  openx,
  outbrain,
  pubmatic,
  sovrn,
  xandar,
  amazon,
  abp,
  ca,
  d,
  e,
  jagran,
  mano,
  oneindia,
  pinkvilla,
  print,
  sakshi,
  tie,
  zee,
  corover,
  docbao,
  erelago,
  patrika,
  vector,
  global,
  caricature_widget,
  content_widget,
  covid_widget,
  newsbot,
  quiz_widget,
  quiz,
  uni_widget,
  weather_widget,
  cricket_widget,
  account_manage,
  app_game,
  app_monetize,
  banner,
  headerbidding,
  native_ads,
  prebid_adapter,
  webstories,
};

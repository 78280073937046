import React from "react";
import { vector } from "../../Assets";
const FeedbackCard = ({
  id,
  content,
  name,
  designation,
  company,
  img,
  index,
}) => {
  return (
    <div
      className={`flex justify-between flex-col px-8 pb-8 rounded-[20px] w-[100%] md:mr-10 sm:mr-5 mr-0 ${
        index === 0
          ? "border-r-2 border-b-2 border-gray-600"
          : index === 3
          ? "border-l-2 border-t-2 border-gray-600"
          : ""
      }`}
    >
      <img
        src={vector}
        alt="double_quotes"
        className={`w-[60px] h-[60px] object-contain ${index === 2 || index === 3 ? "mt-6" :"mt-0"}`}
      />

      <p className="font-poopins font-normal text-[16px] leading[24px] text-[#A1A1AA] my-5">
        {content}
      </p>
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-col">
          <h4 className="font-poopins font-semibold text-[16px] leading[32px] text-white ">
            {name}
          </h4>
          <p className="font-poopins font-normal text-[12px] leading[24px] text-[#A1A1AA] ">
            {designation}
          </p>
          <p className="font-poopins font-normal text-[14px] leading[24px] text-white ">
            {company}
          </p>
        </div>
        <img src={img} alt={name} className=" rounded-full  items-end" />
      </div>
    </div>
  );
};

export default FeedbackCard;

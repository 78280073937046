import React, { useEffect } from "react";
import { app_game } from "../../Assets";
import { ubquiz } from "../../constants/index";
import { animate, motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function Ubquiz() {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const imgAn = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        y: ["20vh", "0vh"],
        transition: { ease: "easeOut", duration: 1 },
        duration: 1,
        bounce: 0.3,
      });
      imgAn.start({
        x: ["20vh", "0vh"],
        transition: { ease: "easeOut", duration: 1.5 },
        duration: 1,
        bounce: 0.3,
      });
    }
  }, [inView]);
  return (
    <>
      <div
        ref={ref}
        className="sm:flex md:flex-row sm:flex-col hidden items-center h-full"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col h-full justify-around pl-4"
        >
          <div className="flex flex-col gap-6">
            <h1 className="text-[24px]">{ubquiz.heading}</h1>
            <h3 className="text-[18px]">{ubquiz.subHeading}</h3>
            <p className="text-[14px] max-w-[400px] text-[#A1A1AA]">
              {ubquiz.content}
            </p>
          </div>
          <div className="sm:flex justify-start lg:hidden">
            <img
              src={app_game}
              alt="video"
              className="object-contain w-[200px]"
            />
          </div>
          <div className="flex gap-4">
            <a href="https://quiztwiz.com/" target="_blank" rel="noreferrer">
              <button className="p-2 border-2 rounded-xl w-[210px] h-[50px]">
                Quiz Platform
              </button>
            </a>
            <a href="https://thegametub.com/" target="_blank" rel="noreferrer">
              <button className="p-2 border-2 rounded-xl w-[210px] h-[50px]">
                Game Platform
              </button>
            </a>
          </div>
        </motion.div>
        {/* image */}
        <motion.div
          initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="lg:flex flex-1 justify-end sm:hidden"
        >
          <img src={app_game} alt="video" />
        </motion.div>
      </div>
      {/* mobile */}
      <div className="sm:hidden mt-4 mb-[2rem]">
        <div className="flex items-stretch h-full p-2">
          <div className="flex flex-col justify-between mt-2 ">
            <div className="flex flex-col items-stretch gap-4">
              <h1 className="text-[16px] text-white">{ubquiz.heading}</h1>
              <h3 className="text-[12px]">{ubquiz.subHeading}</h3>
            </div>
          </div>
          {/* image */}
          <div className="w-[60%]">
            <img src={app_game} alt="video" />
          </div>
        </div>
        <p className="text-[12px] px-2 max-w-[500px] text-[#A1A1AA]">
          {ubquiz.content}
        </p>
        <div className="w-full gap-4 mt-4 flex">
          <a
            href="https://quiztwiz.com/"
            className="w-full"
            target="_blank"
            rel="noreferrer"
          >
            <button className=" leading-[22px] w-[100%] py-2   text-[14px] text-white rounded-lg border-2 border-white">
              Quiz Platform
            </button>
          </a>
          <a
            href="https://thegametub.com/"
            className="w-full"
            target="_blank"
            rel="noreferrer"
          >
            <button className=" leading-[22px] py-2 w-[100%] text-[14px] text-white rounded-lg border-2 border-white">
              Game Platform
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Ubquiz;

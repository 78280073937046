import { useState,useEffect,useContext } from "react";
import { features,whyus } from "../../constants";
import styles, { layout } from "../../style";
import { unibots } from "../../Assets";
import Modal from "../Navbar/ModalDemo";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { LanguageContext } from "../../Context/LangContext";
function Why() {
  const [modalOpen, setIsModalOpen] = useState(false);
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const check = useContext(LanguageContext)
  let sitelang = check.lang
  useEffect(() => {
    if (inView) {
      animation.start({
        y: ["10vh","0vh"],
        transition:{ ease: "easeOut", duration: 1},
        duration: 1,
        bounce: 0.3,
      });
    } 
    // console.log("use effect, in view ", inView);
  }, [inView]);

  return (
    <section className={`relative `}>
      {/* <div className="absolute h-[586px] w-[541px] top-[15%] right-0 z-0">
        <img src={unibots} alt="" />
        </div> */}
      {modalOpen && <Modal setIsModalOpen={setIsModalOpen} />}
      <div className="flex justify-between flex-row ">
        <div>
          <h2 className="font-poppins font-semibold xs:text-[36px] sm:text-[24px] text-[20px] text-white xs:leading-[76.8px] leading-[26.8px] w-full">
           {whyus.heading}
          </h2>
          <p
            className={`${styles.paragraph}sm:py-4 mt-2 mb-6 text-[#A1A1AA] max-w-[770px] text-[14px] sm:text-[16px]`}
          >
            {whyus.content}
          </p>
        </div>
        <div>
          {/* <button
            onClick={() => setIsModalOpen(true)}
            className="text-white py-[14px] sm:inline-block hidden px-[20px] w-[210px] bg-blue-600 font-medium text-xs leading-tight rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            Get a Demo
          </button> */}
        </div>
      </div>
      {/* features */}
      <div ref={ref} className="lg:flex hidden flex-1 flex-wrap lg:gap-12 justify-between">
        {features.map((item) => (
          <motion.div 
          animate={animation} className="w-[21%] z-40 rounded-3xl pl-6  text-white flex-1 my-6 flex flex-col justify-center items-start">
            <img src={item.icon} alt="icon"></img>
            <p className="text-[20px] leading-7 py-2 mt-2 font-normal">
              {item[sitelang+"title"]}
            </p>
            <p className="flex flex-1 text-[16px] leading-[24px] text-[#A1A1AA] w-full min-w-[200px] max-w-[300px]  py-2">
              {item[sitelang+"content"]}
            </p>
          </motion.div>
        ))}
      </div>

      {/* mobile */}
      <div className="flex flex-col lg:hidden flex-1 flex-wrap  justify-between">
        {features.map((item) => (
          <div  className="z-40 rounded-3xl pl-2 gap-2 text-white  my-2 flex flex-1 justify-center items-center">
            <div className="w-[90px] pr-4">
              <img
                src={item.icon}
                className="object-contain"
                alt="icon"
              ></img>
            </div>
            <div className="sm:max-w-full w-[80%] sm:pr-4">
              <p className="sm:text-[20px] text-[16px] max-w-full sm:leading-3 sm:py-2 mt-2 font-normal">
              {item[sitelang+"title"]}
              </p>
              <p className="sm:text-[16px] text-[14px] sm:max-w-[90%] leading-[24px] text-[#A1A1AA]  py-2">
              {item[sitelang+"content"]}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Why;

// border-4 z-40 rounded-3xl sm:pl-6 feature-card text-white sm:my-6 my-2 flex flex-1 sm:flex-col sm:gap-4 justify-center items-center sm:items-start

import React from 'react'

function Neon(props) {
  return (
    <div className="flex flex-1 justify-center items-end">
    <div className={"w-20  h-5 absolute rounded-xl  blur-[30px] " + props.bg}></div>
    <svg width="100%" height="100px" viewBox="0 0 120 70">
        <text
            x="50%"
            y="50%"
            text-anchor="middle"
            dominant-baseline="middle"
            className={"text-out text-5xl font-bold " + props.stroke}
        >
            {props.heading}
        </text>
    </svg>
    <div className="absolute flex flex-col text-center">
        <div className={"text-3xl font-semibold " + props.text}>
            {props.heading}
        </div>
        <div className="text-white">{props.sub}</div>
    </div>
</div>
  )
}

export default Neon
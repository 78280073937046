import {
  cloud,
  bharatpe,
  data,
  priceTag,
  pieChart,
  rocketWhy,
  steps,
  across33,
  amazon,
  google,
  magnite,
  nobid,
  openx,
  outbrain,
  pubmatic,
  sovrn,
  xandar,
  abp,
  ca,
  d,
  e,
  jagran,
  webstories,
  mano,
  oneindia,
  pinkvilla,
  print,
  sakshi,
  tie,
  zee,
  corover,
  docbao,
  erelago,
  patrika,
  vector,
  caricature_widget,
  content_widget,
  covid_widget,
  newsbot,
  quiz_widget,
  uni_widget,
  weather_widget,
  cricket_widget,
  service,
  widgets,
  amarujala,
  quiz,
  moneycontrol,
  republicworld,
  news18,
  trainman,
  goldprice_widget,
  polls_widget,
  // triplex_widget,
} from "../Assets";

export const navLinks = [
  {
    id: "1",
    title: "Solutions",
    english: "Solutions",
    indonesian: "Larutan",
  },
  {
    id: "2",
    title: "Partners",
    english: "Partners",
    indonesian: "Mitra",
  },
  {
    id: "3",
    title: "About Us",
    english: "About Us",
    indonesian: "Tentang kami",
  },
  {
    id: "4",
    title: "Get a Demo",
    english: "Get in Touch",
    indonesian: "Dapatkan Demo",
  },
];

export const hero = {
  heading: "NEXT GEN ADVERTISING,EVOLVED",
  subheading:
    "EMPOWERING YOUR DIGITAL SPACE WITH NEXT GENERATION ADVERTISING SOLUTIONS",
};

export const features = [
  {
    id: 1,
    icon: priceTag,
    title: "Lightning Fast Payments",
    englishtitle: "Lightning Fast Payments",
    indonesiantitle: "Pembayaran Cepat Kilat",
    englishcontent:
      "Fast and reliable payment terms starting from Net 7 for qualified products and partners with industry-leading payment terms.",
    indonesiancontent:
      "Kami secepat Google. Ketentuan Pembayaran kami mulai dari Net-7",
  },
  {
    id: 3,
    icon: steps,
    englishtitle: "High Fill Rates",
    indonesiantitle: "Tarif Pengisian 100%",
    englishcontent:
      "Our UB SmartFill technology helps to achieve the absolute highest possible fill rate for your ad inventory.",
    indonesiancontent:
      "Mencapai 100% Fill Rate melalui Teknologi UB Smartfill. Sekarang capai rasio pengisian 100% pada inventaris iklan Anda",
  },
  {
    id: 2,
    icon: pieChart,
    englishtitle: "100% Transparency",
    indonesiantitle: "Transparansi 100%",
    englishcontent:
      "We’re transparent in everything we do. All UB products include a publisher dashboard with realtime reporting. There is no hidden fee.",
    indonesiancontent:
      "Kami memastikan untuk tetap transparan dengan mitra penerbit kami. Kami menjadwalkan laporan langsung dari Google Ad Manager 360",
  },
  {
    id: 7,
    icon: service,
    englishtitle: "Dedicated Customer Service",
    englishcontent: "Our support team is extremely dedicated and active.",
  },

  {
    id: 5,
    icon: rocketWhy,
    englishtitle: "One Stop Solution",
    indonesiantitle: "One Stop untuk Penerbit",
    englishcontent:
      "Unibots offers a complete digital monetization program for publishers around the globe.",
    indonesiancontent:
      "Dari monetisasi situs web lengkap hingga monetisasi aplikasi, Unibots adalah tujuan lengkap bagi penerbit di seluruh Dunia",
  },
  {
    id: 6,
    icon: data,
    englishtitle: "Privacy & Policy Compliant",
    indonesiantitle: "Penjual Json & SCO Compliant",
    englishcontent:
      "We ensure 100% sellers.json and supply chain compliance in order to deliver the best possible ad revenues.",
    indonesiancontent:
      "Kami memastikan 100% penjual.json dan kepatuhan rantai pasokan untuk memberikan pendapatan iklan sebaik mungkin",
  },
  {
    id: 4,
    icon: cloud,
    englishtitle: "Smart Refresh",
    indonesiantitle: "Penyegaran Cerdas",
    englishcontent:
      "Our smart refresh considers 20 different factors before refreshing an ad unit to maximize your ad revenues.",
    indonesiancontent:
      "UB Smart Refresh meningkatkan pendapatan iklan hingga berlipat ganda. Penyegaran cerdas kami mempertimbangkan 20 faktor berbeda sebelum menyegarkan unit iklan",
  },
  {
    id: 8,
    icon: widgets,
    englishtitle: "Engaging Widgets",
    englishcontent:
      "We are the first ones to offer a wide variety of creative widgets to monetize your digital assets.",
  },
];
export const partners = [
  {
    id: 1,
    logo: google,
    type: "premium",
  },
  {
    id: 2,
    logo: xandar,
    type: "premium",
  },
  {
    id: 7,
    logo: amazon,
    type: "premium",
  },
  {
    id: 4,
    logo: magnite,
    type: "premium",
  },
  {
    id: 5,
    logo: openx,
    type: "premium",
  },
  {
    id: 6,
    logo: sovrn,
    type: "premium",
  },
  {
    id: 3,
    logo: across33,
    type: "premium",
  },
  {
    id: 36,
    logo: bharatpe,
    type: "publisher",
  },
  {
    id: 35,
    logo: republicworld,
    type: "publisher",
  },
  {
    id: 10,
    logo: abp,
    type: "publisher",
  },
  {
    id: 37,
    logo: trainman,
    type: "publisher",
  },

  {
    id: 23,
    logo: amarujala,
    type: "publisher",
  },
  {
    id: 34,
    logo: moneycontrol,
    type: "publisher",
  },
  {
    id: 11,
    logo: ca,
    type: "publisher",
  },
  {
    id: 12,
    logo: jagran,
    type: "publisher",
  },
  {
    id: 8,
    logo: e,
    type: "publisher",
  },
  {
    id: 19,
    logo: zee,
    type: "publisher",
  },
  {
    id: 16,
    logo: print,
    type: "publisher",
  },
  {
    id: 13,
    logo: mano,
    type: "publisher",
  },
  {
    id: 38,
    logo: news18,
    type: "publisher",
  },
  {
    id: 18,
    logo: tie,
    type: "publisher",
  },
  {
    id: 14,
    logo: oneindia,
    type: "publisher",
  },
  {
    id: 15,
    logo: pinkvilla,
    type: "publisher",
  },

  {
    id: 17,
    logo: sakshi,
    type: "publisher",
  },

  {
    id: 9,
    logo: d,
    type: "publisher",
  },

  {
    id: 20,
    logo: outbrain,
    type: "premium",
  },
  {
    id: 21,
    logo: pubmatic,
    type: "premium",
  },
  {
    id: 22,
    logo: nobid,
    type: "premium",
  },
];

export const feedbackTitle = {
  heading: "Don’t Just Take Our Word For It",
  content: "Here’s a little something of what our publishers think about us",
};

export const feedback = [
  {
    id: "1",
    content:
      "“The Best part about the unibots.in is nothing but the transparency.In my whole career, no one shared dashboard for real time Revenue.We witnessed direct 18% jump in our video ad revenues. Must Try!",
    name: "Shekhar Suman",
    designation: "Bussiness Head",
    company: "Digital Rajasthan Patrika",
    img: patrika,
  },
  {
    id: "2",
    content:
      "“I was considering Unibots another demand partner but I must say their solutions worked extremely well for us. They have helped in REAL lift of ad revenues. Moreover, they are the quickest team I have ever worked with.",
    name: "Sudeer Shetty",
    designation: "Co-Founder and CBO",
    company: "Erelego",
    img: erelago,
  },
  {
    id: "3",
    content:
      "“Very impressed by the professional working style. Always show enthusiasm and dedicated support towards partners”",
    name: "Docbao",
    designation: "Owner",
    company: "Docbao",
    img: docbao,
  },
  {
    id: "4",
    content:
      "“One-stop place for my entire inventory monetization . They are really quick & transparent.”",
    name: "Ankush Sabharwal",
    designation: "CoFounder and CEO",
    company: "CoRover",
    img: corover,
  },
];

export const numbers = [
  {
    id: 1,
    number: 3,
    content: "Billion Ad Impressions",
  },
  {
    id: 2,
    number: 450,
    content: "Millions Users",
  },
  {
    id: 3,
    number: 1000,
    content: "Active Publishers",
  },
  {
    id: 4,
    number: 40,
    content: "Advertisers",
  },
];

export const product = [
  {
    id: 1,
    name: "UB Widgets",
  },
  {
    id: 2,
    name: "UB Videos",
  },
  {
    id: 3,
    name: "UB Banner Ads",
  },
  {
    id: 4,
    name: "Prebid Adapter",
  },
  {
    id: 5,
    name: "UB Header Bidding",
  },
  {
    id: 6,
    name: "UB App Monetize",
  },
  {
    id: 7,
    name: "UB Managed Account",
  },
  {
    id: 8,
    name: "UB Quiz/Gaming",
  },
  {
    id: 9,
    name: "UB Native 360",
  },
];

export const Sports = {
  content:
    "Live sports data wrapped up in a creative widget with quick and hassle free integration.",
};
export const webstory = {
  content:
    "Web Stories widget allows websites to showcase visually engaging, short-form content in a carousel format.",
};
export const Weather = {
  content:
    "Get instant access to accurate weather data with UB weather widget.",
};
export const Quiz = {
  content:
    "A revenue-boosting quiz widget for websites that helps increase revenue by creating engaging quizzes for visitors.",
};
export const Game = {
  content:
    "Get plug and play HTML5 gaming widget on your website and make upto 40% more incremental revenues.",
};
export const Chatbot = {
  content:
    "A smart virtual assistant that supports customer service by adding a new layer of automation and speed to your website.",
};
export const Caricature = {
  content:
    "Cartoon widget merged with real time data of any kind. Be it sports, weather or anything else. We've got you covered!",
};
export const Covid = {
  content:
    "Embed our automated covid widget on your website and give your website users access to latest covid figures.",
};
export const Content = {
  content:
    "Monetize your digital footprint with the help of our unique content widget. An effortless way to post ads on your website.",
};
export const Universal = {
  content:
    "Display sports data without having to remove the regular weather updates. Our universal widget helps you showcase more than just one thing!",
};

export const Polls = {
  content:
    "Elevate User Engagement with Our Interactive Polls that can be used in various sports and political events.",
};

export const Gold = {
  content:
    "Experience the Beauty of Real-Time Gold and Silver Market Data: Elevating User Interaction on Your Website with this informational widget.",
};
export const Triplex = {
  content:
    "Advertise Smart, not big with our Triplex side Banner by unlocking the power of three ads in one.",
};
export const moreWidgets = [
  {
    id: 8,
    icon: covid_widget,
    name: "Covid Widget",
  },
  {
    id: 11,
    icon: caricature_widget,
    name: "Caricature Widget",
  },
  {
    id: 12,
    icon: polls_widget,
    name: "Polls Widget",
  },
  {
    id: 13,
    icon: goldprice_widget,
    name: "Gold Price Widget",
  },
  {
    id: 14,
    icon: uni_widget,
    name: "Universal Widget",
  },
];

export const ubwidget = [
  {
    id: 1,
    icon: cricket_widget,
    name: "Sports Widget",
  },
  {
    id: 2,
    icon: weather_widget,
    name: "Weather Widget",
  },
  {
    id: 3,
    icon: quiz,
    name: "Quiz Widget",
  },
  {
    id: 4,
    icon: newsbot,
    name: "Chatbot Widget",
  },
  {
    id: 5,
    icon: quiz_widget,
    name: "Game Widget",
  },
  {
    id: 6,
    icon: webstories,
    name: "Web Stories",
  },
  {
    id: 7,
    icon: content_widget,
    name: "Content Widget",
  },
];

export const productIntro = {
  heading: "Publisher Market Place",
  content:
    "Our unique combination of products are time-tested & tailor-made to meet your business needs. We offer publishers a full stack of engagement and ad monetization solutions that turn their digital space into a revenue stream.",
};

export const ubvideos = {
  heading: "UB Video Player",
  subHeading: "For Unique Video Content & Ads",
  content:
    "Our AI driven video player is powered with leading direct and programmatic advertisers. With UB player, you can increase your Ad revenues up to 60%. With our advanced in-house optimization technology, compliant video format and years’ of ad tech experience, our UB player offers exceptional CPMs and fill rates. B.E.S.T in the industry!",
};

export const ubquiz = {
  heading: "UB Quiz/Games",
  subHeading: "For Incremental Revenues and Better Engagement",
  content:
    "UB Quiz/Games are our champion of ad revenues. Make up to 40% more incremental revenues with our HTML5 gaming & quiz product. Plug & Play solutions to drive better engagement & incremental revenues.",
};

export const ubnative = {
  heading: "UB Native 360",
  subHeading: "Access to Best of Native Advertisers",
  content:
    "Create unique and relevant advertising experience using UB Native 360 widget. We have partnered with almost all the top native demand partners across the globe to ensure that your revenues never take a back seat with Unibots.",
};

export const ubmanage = {
  heading: "UB Managed Account",
  subHeading: "Self Managed Adx for Web & App",
  content:
    "Get self-managed Google Ad Exchange account. Manage all your campaigns on your own with UB Managed Account. Easy Payments & Setup. Enjoy dedicated account management.  An account manager will always be available to ensure you’re doing everything you can for the highest performance of your ads.",
};

export const header = {
  heading: "UB Header Bidding",
  subHeading: "Upto 60% More Ad Revenues",
  content:
    "We have designed our own header bidding solution for publishers that we set up free of cost. Our in-house developed header bidding solution gives you access to UB premium demand that can increase your ad revenue up to 300%.",
};

export const ubprebid = {
  heading: "Prebid Adapter",
  subHeading: "To Access Premium UB Advertisers",
  content:
    "Sign your own Prebid Wrapper or a 3rd party's header bidding solution? Get access to Unibot's unique direct and programmatic demand in minutes with UB Adapter.",
};

export const ubapp = {
  heading: "UB App Monetization",
  subHeading: "Monetizing Android, IOS & CTV Apps",
  content:
    "Unlock your app’s true revenue potential with the help of UB App Monetization. Our rich collection of ad formats and access to premium advertisers elevates user experience and maximizes ROI.",
};

export const ubbanner = {
  heading: "UB Banner Ads",
  subHeading: " Access To Best HeaderBidding & Open Bidding Solution",
  content:
    "UB banner ads are a one stop solution for all your display requirements including standard IAB units. Our unique combination of Unibot’s Header Bidding and Google’s open bidding topped up with direct demand magically uplifts revenues for publishers. Get 100% fill rate guaranteed with UB smartfill. With Unibots, you can also leave it on autopilot. Our ad specialists can take charge of your ad unit customizations for you if you don’t have the time.",
};

export const whyus = {
  heading: "Why Unibots?",
  content: "Our products are built for publishers",
};

export const premiumPartners = {
  heading: "Our Premium Partners",
  content:
    "We work with 40+ Advertisers across the globe to provide access to most premium demand across all campaign types and regions for our publisher partners.",
};

export const premiumPublishers = {
  heading: "Our Happy Publishers",
  content:
    "We work with 700+ Publishers across the globe and here is a glimpse.",
};

export const footer = {
  heading: "Our Global Presence",
  content: "We provide services to our customers around the Globe.",
  mission:
    "Unibots is a Next Generation Ad-Tech company which is on a mission to build unique monetisation solutions for publishers across the globe.",
};

import React, { useEffect } from "react";
import styles from "../../style";
import { numbers } from "../../constants";
import Neon from "./Neon";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function Numbers() {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        y: ["20vh", "0vh"],
        transition: { ease: "easeOut", duration: 1 },
        duration: 1,
        bounce: 0.3,
      });
    }
    // console.log("use effect, in view ", inView);
  }, [inView]);
  return (
    <section
      className={`${styles.flexCenter}  md:border-b-2 border-gray-400 pb-6`}
    >
      <div ref={ref} className={` gap-8 text-white flex-wrap w-full`}>
        <h1 className="font-poppins font-semibold xs:text-[36px] text-[24px] text-white xs:leading-[76.8px] sm:leading-[66.8px] leadind-2 w-full text-center">
          Working with 700+ Publishers
        </h1>
        <motion.div
          animate={animation}
          className={`flex-1 flex flex-col sm:flex-row sm:min-w-[192px] min-w-[120px] sm:mt-4`}
        >
          {/* {numbers.map((item) => (
        <>
        <div className='w-[25%] flex flex-col justify-center items-center relative'>
          <div className='absolute text-[60px] bottom-8 font-extrabold z-0 text-yellow-500 text-opacity-10'>{item.number}+</div>
          <p className='font-bold text-[40px] text-[#EBFF00] z-10 drop-shadow-2xl outline-4 glow'>{item.number}+</p>
          <p>{item.content}</p>
        </div>
        </>
          ))
        } */}
          <Neon
            bg="bg-[#ff0]"
            text="text-[#ff0]"
            stroke="stroke-[#ff0]"
            heading="5+"
            sub="Billion Ad Impressions"
          />
          <Neon
            bg="bg-green-500"
            text="text-green-500"
            stroke="stroke-green-500"
            heading="350+"
            sub="Million Users"
          />
          <Neon
            bg="bg-red-500"
            text="text-red-500"
            stroke="stroke-red-500"
            heading="700+"
            sub="Active Publishers"
          />
          <Neon
            bg="bg-orange-500"
            text="text-orange-500"
            stroke="stroke-orange-500"
            heading="40+"
            sub="Advertisers"
          />
        </motion.div>
      </div>
    </section>
  );
}

export default Numbers;
